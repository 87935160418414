// ICD10Autocomplete.vue
<template>
    <v-autocomplete v-model="selectedCodes" :loading="isLoading" :items="filteredCodes" :search-input.sync="search"
        @update:search="handleSearch" multiple chips closable-chips clearable flat variant="underlined" no-resize
        persistent-hint :hint="hint" item-title="display" item-value="display" @update:modelValue="handleChange">
        <template v-slot:chip="{ props, item }">
            <v-chip v-bind="props" :text="item.raw.display"></v-chip>
        </template>

        <template v-slot:no-data>
            <v-list-item>
                <v-list-item-title>
                    Start typing to search ICD-10 codes
                </v-list-item-title>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>

<script>
import { ref, watch } from 'vue';
import { debounce } from 'lodash';
import icd10CodesData from '@/assets/icd10cm-codes-April-2024.json';

export default {
    name: 'ICD10Autocomplete',

    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        hint: {
            type: String,
            default: ''
        }
    },

    setup(props, { emit }) {
        const selectedCodes = ref(props.modelValue);
        const search = ref('');
        const isLoading = ref(false);
        const filteredCodes = ref([]);

        // Prepare codes with a display property that includes both code and description
        const preparedCodes = icd10CodesData.map(item => ({
            code: item.code,
            description: item.description,
            display: `[${item.code}] ${item.description}`,
            searchText: `${item.code} ${item.description}`.toLowerCase()
        }));

        const handleSearch = debounce(async (searchText) => {
            if (!searchText || searchText.length < 2) {
                filteredCodes.value = [];
                isLoading.value = false;
                return;
            }

            isLoading.value = true;

            // Simulate async operation for better UI responsiveness
            await new Promise(resolve => setTimeout(resolve, 0));

            const searchLower = searchText.toLowerCase();
            filteredCodes.value = preparedCodes
                .filter(item => item.searchText.includes(searchLower))
                .slice(0, 100); // Limit results for better performance

            isLoading.value = false;
        }, 300);

        const handleChange = (newValue) => {
            emit('update:modelValue', newValue);
        };

        // Watch for external changes to modelValue
        watch(() => props.modelValue, (newValue) => {
            selectedCodes.value = newValue;
        });

        return {
            selectedCodes,
            search,
            isLoading,
            filteredCodes,
            handleSearch,
            handleChange
        };
    }
};
</script>