<template>
    <span>
        <v-btn class="white--text text--lighten-1" @click="openDialog" icon size="x-large" :elevation="0"
            :id="patientId" v-if="type === 'icon' && !isZoomApp" density="compact" rounded>
            <v-icon color="#FF7260">mdi-microphone</v-icon>
        </v-btn>

        <v-btn class="white--text text--lighten-1" color="bittersweet" elevation="0" @click="openDialog"
            v-else-if="type === 'full' && !isZoomApp">
            <v-icon color="white" icon="mdi-microphone"></v-icon>
            <span class="ml-2" style="text-transform: none; color:white;"> {{ recording ? 'Stop Recording' :
                'Start Recording' }}
            </span>
        </v-btn>

        <div class="recorder-button-container" v-else>
            <v-btn class="recorder-btn" color="bittersweet" @click="toggleCaptureZoomApp"
                :recording="recording ? 'true' : 'false'">
                <v-icon dense color="white">mdi-microphone</v-icon>
                <span class="white--text"> {{ recording ? 'Stop Recording' : 'Start Recording' }}
                </span>
            </v-btn>
        </div>

        <v-snackbar color="gray" :timeout="6000" v-model="alreadyRecordingSnackbar">{{ alreadyRecordingText
            }}</v-snackbar>

        <v-dialog v-model="zoomDialog" persistent max-width="500px">
            <v-card>
                <v-card-title class="ma-4">
                    <v-icon large class="mr-4" :class="{ 'pulsing-icon': true }">mdi-microphone</v-icon>
                    <span class="text-h5">Recording in progress</span>
                </v-card-title>
                <v-card-text>
                    <p>- Click "Stop Recording" or end your Zoom call to initiate the audio upload.</p>
                    <p>- Zoom may need time to process the audio.</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" @click="toggleCaptureZoomApp">
                        <v-icon left>mdi-stop</v-icon>
                        Stop Recording
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="downloadAudioDialog" width="400" persistent>
            <v-card class="d-flex flex-column align-start pa-5">
                <h5>Problem uploading audio</h5>
                <p>Click download to save file locally on your device.</p>
                <p>Please, upload file manually</p>
                <v-btn class="ml-auto action-button white--buton" @click="downloadAudioBlobLocally">Download</v-btn>
            </v-card>

        </v-dialog>

        <v-overlay :model-value="zoomOverlay" :persistent="true" class="align-center justify-center spinner-overlay">
            <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </span>
</template>

<script setup>
import { ref, defineProps } from 'vue';
import { getIsZoomApp, getZoomMeetingID, getZoomMeetingUUID } from '@/composables/configureZoom'
import { useRecorderStore } from '@/store/recorder'
import { storeToRefs } from 'pinia'
import getUser from '@/composables/getUser'
import { getFunctions, httpsCallable } from "firebase/functions";
import useDocument from '@/composables/useDocument'
import { trackEvent, RECORDER_DOWNLOADED_AUDIO, RECORDER_DOWNLOAD_NO_AUDIO_BLOB } from '@/utilities/analyticsService';
import { getPatientFullName } from '@/utilities/patientUtils';

const store = useRecorderStore();
const { recording, patientId, patient, downloadAudioDialog, sessionId } = storeToRefs(store)
const { toggleDrawer, getBlob, setSessionId, clear } = store

const { user } = getUser();

const props = defineProps({
    type: String,
    patientId: String,
    patient: Object
});


const { isZoomApp } = getIsZoomApp();

const alreadyRecordingSnackbar = ref(false);
const alreadyRecordingText = ref('');

const openDialog = () => {
    if (recording.value && props.patientId !== patientId.value) {
        alreadyRecordingText.value = `Recording is already in progress for ${patient.value.patientFirstName 
            ? `${patient.value.patientFirstName} ${patient.value.patientLastName || ''}`.trim() 
            : patient.value.patientName}. Click the recording indicator in the Navbar to continue.`;
        alreadyRecordingSnackbar.value = true;
        return
    }
    toggleDrawer(props.patient);
};

// Zoom stuff
const zoomRecording = ref(false);
const zoomDialog = ref(false);
const zoomOverlay = ref(false)

const toggleCaptureZoomApp = async () => {
    console.log('currently recording: ', zoomRecording.value)
    if (zoomRecording.value) {
        zoomDialog.value = false
        stopRecordingWithZoom()
    } else {
        zoomDialog.value = true
        zoomOverlay.value = true
        console.log('starting recording', zoomDialog.value, zoomOverlay.value)
        await startRecordingWithZoom()
    }
}

const stopRecordingWithZoom = () => {
    const api = {
        buttonName: "cloudRecording(stop)",
        name: "cloudRecording",
        options: { action: "stop" },
    }
    const { name, buttonName = '', options = null } = api
    const zoomAppsSdkApi = zoomSdk[name].bind(zoomSdk)

    zoomAppsSdkApi(options)
        .then(async clientResponse => {
            zoomRecording.value = false
            // trackEvent(ZOOM_RECORDING_ENDED, { userId: this.userId, patientId: this.patientId, source: "Zoom" })

            console.log(`${buttonName || name} success with response: ${JSON.stringify(clientResponse)}`);
        })
        .catch(clientError => {
            console.log(`${buttonName || name} error: ${JSON.stringify(clientError)}`);
        });
}

const startRecordingWithZoom = async () => {
    const api = {
        buttonName: "cloudRecording(start)",
        name: "cloudRecording",
        options: { action: "start" },
    }
    const { name, buttonName = '', options = null } = api
    const zoomAppsSdkApi = zoomSdk[name].bind(zoomSdk)

    console.warn('recording...')
    const clientResponse = await zoomAppsSdkApi(options);
    console.log(`${buttonName || name} success with response: ${JSON.stringify(clientResponse)}`);
    // trackEvent(ZOOM_RECORDING_STARTED, { userId: userId, patientId, source: "Zoom" })

    zoomRecording.value = true
    zoomOverlay.value = false

    await updateZoomMeeting()
}

const updateZoomMeeting = async () => {
    console.warn('calling zoom update')
    const meetingId = await getZoomMeetingID();
    const meetingUUID = await getZoomMeetingUUID()

    console.warn('got the ids', meetingId, meetingUUID)

    const res = await createNewSession()

    const { updateDocument } = useDocument('sessions', res.id);
    await updateDocument({
        zoomMeetingId: meetingId,
        zoomMeetingUUID: meetingUUID
    });

    // trackEvent(ZOOM_RECORDING_UPDATE_MEETING, { userId: userId, patientId: patientId, sessionId: res.id, meetingId, source: "Zoom" })

    console.log('Zoom meeting updated in firestore');
}

const createNewSession = async () => {
    console.log("USER: ", user.value)
    zoomOverlay.value = true;
    const functions = getFunctions();
    const createNewSessionFunction = httpsCallable(functions, 'createSession');
    const payload = { userId: user.value.uid, patientId: props.patientId, transcript: '', type: 'live', additionalNotes: '', source: 'web' };
    console.log('payload: ', payload)
    const result = await createNewSessionFunction(payload);
    // trackEvent(RECORDING_SESSION_CREATED, { userId: userId, userEmail: user.value.email, patientId: patientId, sessionId: result.data.id, source: "Web" })
    zoomOverlay.value = false;

    return result.data
}


const downloadAudioBlobLocally = async () => {
    const localBlob = getBlob()
    
    console.log("BLOB", localBlob)
    if (!localBlob) {
        console.error("No audio blob found to download.");
        trackEvent(RECORDER_DOWNLOAD_NO_AUDIO_BLOB, { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId.value, patientId: patientId.value })
        setSessionId('')
        return;
    }
    const url = window.URL.createObjectURL(localBlob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${getPatientFullName(patient.value)}_${sessionId.value}.webm`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);

    downloadAudioDialog.value = false

    trackEvent(RECORDER_DOWNLOADED_AUDIO, { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId.value, patientId: patientId.value, blob: localBlob })
    setSessionId('')
    await clear()
}
</script>

<style scoped>
.recorder-button-container {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    margin-right: 50px;
    z-index: 999;
}

.recorder-btn {
    background-color: var(--bittersweet) !important;
    border-radius: 30px !important;
    color: var(--white) !important;
    flex-grow: 0;
    height: 50px !important;
    width: 200px;
}
</style>
