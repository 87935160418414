<template>
    <div>
        <v-container fluid>
            <v-row class="mt-0 mb-0 pt-0 pb-0 d-flex justify-space-between align-center">
                <!-- Combined title and close button in a single column for better alignment control -->
                <v-col cols="12" class="container d-flex justify-start align-center">
                    <!-- Title aligned to the left -->
                    <div class="d-flex flex-column pl-4">
                        <span v-if="patient && (patient.patientName || patient.patientFirstName)" class="title flex-grow-1 text-start">Ask your mdhub
                            assistant anything you like about {{
                                getPatientFullName(patient) }} past sessions</span>
                        <span class="sub-title flex-grow-1 text-start">Mark your sessions as "Reviewed" to get faster
                            answers</span>
                    </div>
                    <!-- Spacer to push the button to the right -->
                    <v-btn icon class="ml-auto mr-12" @click="handleClose" variant="plain">
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <ChatInterface :searchText="searchText" :patientId="patientId"></ChatInterface>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ChatInterface from '@/components/ChatInterface.vue';
import { useRouter } from 'vue-router'
import getDocument from '@/composables/getDocument'
import { getPatientFullName } from '@/utilities/patientUtils';

export default {
    props: {
        patientId: String
    },
    components: {
        ChatInterface
    },
    computed: {
        searchText() {
            return this.$route.query.searchText;
        }
    },
    setup(props) {
        const router = useRouter()
        const handleClose = async () => {
            router.push({ name: 'PatientOverview', params: { id: props.patientId } })
        }
        const { error, document: patient } = getDocument('patients', props.patientId)
        return { 
            handleClose, 
            patient,
            getPatientFullName
        }
    }
};


</script>

<style scoped>
.container {
    padding-left: 20%;
    padding-right: 20%;
}

.title {
    font-weight: bold;
    font-size: 18px;
}
</style>