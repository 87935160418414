<template>
    <div>
        <v-card elevation="0" class="rounded-card d-flex flex-column py-4 px-5">
            <div class="d-flex">
                <div class="text-h6 text-left">Treatment Plan</div>
                <v-btn class="ml-auto" variant="text" @click="openDrawer">
                    <span style="color:var(--bittersweet); text-transform: none"> {{!patient.treatmentPlan ? 'Generate' : 'Regenerate'}} </span>
                </v-btn>
            </div>

            <div v-if="creatingPlan" class="d-flex align-center justify-center my-5">
                <v-progress-circular class="mr-1" indeterminate :rotate="90" :size="20"
                    :width="2"></v-progress-circular>
                <p class="mb-0 ml-3">Generating Treatment plan...</p>
            </div>

            <v-table v-else-if="patient.treatmentPlan">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Created at</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" @click="redirect">
                        <td class="text-left">{{ getPatientFullName(patient) }}'s Treatment Plan</td>
                        <td class="text-left">{{ formatDate(patient.treatmentPlan.createdAt) }}</td>
                    </tr>
                </tbody>
            </v-table>

            <div v-else class="my-5 text-left">
                No treatment plan created
            </div>
        </v-card>
        <v-navigation-drawer v-model="drawer" temporary location="right" :width="800">
            <div class="d-flex flex-column pa-10 text-left">
                <h4>Create Treatment plan for {{ getPatientFullName(patient) }}</h4>
                <v-select class="mt-5" label="Select a session" v-model="sessionId" :items="sessions" item-value="id"
                    :item-title="formatSessionDate"></v-select>

                <div class="d-flex justify-end mt-5">


                    <v-btn variant="text">
                        <span style="color:var(--bittersweet); text-transform: none"
                            @click="drawer = false; sessionId = null"> Cancel </span>
                    </v-btn>

                    <v-btn elevation="0" width="auto" class="mdhub-btn ml-3" @click="createTreatmentPlan">
                        Submit</v-btn>
                </div>
            </div>
        </v-navigation-drawer>

        <v-snackbar v-model="errorSnackbar" :timeout="2000" color="bittersweet">
            <span style="color:white;">Failed to create treatment plan.</span>
        </v-snackbar>
    </div>

</template>

<script setup>
import { ref, defineProps } from 'vue';
import moment from 'moment'
import { getFunctions, httpsCallable } from "firebase/functions";
import { useRouter } from 'vue-router'
import { trackEvent, TREATMENT_PLAN_CREATE, TREATMENT_PLAN_CREATE_ERROR } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'
import { getPatientFullName } from '@/utilities/patientUtils';

const { user } = getUser()
const router = useRouter()
const props = defineProps({
    patient: Object,
    sessions: Array
});


const sessionId = ref(null)
const creatingPlan = ref(false)
const errorSnackbar = ref(false)

const createTreatmentPlan = async () => {

    if (!sessionId.value) return

    creatingPlan.value = true;
    drawer.value = false;

    const functions = getFunctions();
    const createTreatmentPlanFunc = httpsCallable(functions, 'createTreatmentPlan');

    try {
        await createTreatmentPlanFunc({ sessionId: sessionId.value });
        trackEvent(TREATMENT_PLAN_CREATE, { userId: user.value.uid, userEmail: user.value.email, patientId: props.patient.id });

    } catch (error) {
        console.error('Error creating treatment plan', error);
        errorSnackbar.value = true;
        trackEvent(TREATMENT_PLAN_CREATE_ERROR, {
            userId: user.value.uid,
            userEmail: user.value.email,
            patientId: props.patient.id,
            error: {
                errorName: error.name,
                errorMessage: error.message,
                errorStack: error.stack
            }
        })
    } finally {
        creatingPlan.value = false;
    }
}


const drawer = ref(false)
const openDrawer = () => {
    drawer.value = true
}

const formatDate = (date) => {
    return moment(date.toDate()).format('MMM D, YYYY, h:mm A');
}
const formatSessionDate = (item) => {
    return moment(item.date.toDate()).format('MMM D, YYYY, h:mm A');
}

const redirect = () => {
    router.push(`/patients/${props.patient.id}/treatment-plan`)
}
</script>

<style scoped>
.table-row {
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.table-row:hover {
    background-color: rgba(var(--v-theme-primary), 0.05) !important;
}
</style>