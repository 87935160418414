export const TEMPLATE_SECTIONS = [
    'Chief Complaint',
    // HPI
    'History of Present Illness',
    'History of Present Illness - detailed',
    'Subjective',
    // History section
    'Past Psychiatric History',
    'Past Psychiatric History - Detailed',
    'Review of Psychiatric History',
    'Educational History',
    'Professional History',
    'Social and Family History',
    'Family History',
    'Social History',
    'Interim History',
    'Trauma History',
    'Past Medical and Surgical History',
    'Spiritual and Cultural Factors',
    'Developmental History',
    'Employment History',
    // Review of Systems
    'Review of Systems',
    'General Review of Function',
    'Mental Status Exam',
    'Objective',
    'Vital Signs',
    'Symptoms',
    // Now
    'Substance Use',
    'Current Medication',
    // Assessment
    'Assessment',
    'Assessment (BPS)',
    'DAYC-2',
    'Assessment and Plan',
    'Risk Assessment',
    'Problem List',
    'Problem list (excl. ICD 10)',
    'Problems Discussed',
    'DSM-5',
    // Plan
    'Plan',
    'Plan (excl. ICD 10)',
    'Protective Factors',
    'Psychotherapeutic Interventions',
    // Progress and next steps
    'Treatment Progress',
    'Patient Goals',
    'CPT',
    // New items
    'Client Strengths',
    'Clinical Summary',
    'Treatment Goal 1',
    'Treatment Goal 2',
    'Treatment Approaches',
    'Interventions',
    'Response to Interventions',
    'Plan and Homework',
    'Treatment Goal Progress',
    'Functional Impairment Areas',
    'Impact of Symptoms on Client Functioning',
    'Session Focus',
    'Progress towards Treatment Goals',
];