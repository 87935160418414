<template>
  <div class="mr-15 ml-15 pb-12">
    <div class="error" v-if="patientsError">{{ patientsError }}</div>
    <div class="error" v-if="sessionsError">{{ sessionsError }}</div>
    <div class="session-notes">
      <div class="mt-6">
        <div class="d-flex">
          <h5 class="d-flex flex-row me-auto">Inbox</h5>
        </div>
        <v-chip-group mandatory multiple v-model="typesFilter">
          <v-chip filter label value="Notes">Notes</v-chip>
          <v-chip filter label value="Questionnaires">Questionnaires</v-chip>
        </v-chip-group>

        <v-chip-group mandatory v-model="chipFilter">
          <v-chip filter label value="All">
            All
          </v-chip>
          <v-chip filter label :value="statusEnum.ReadyForReview">
            {{ statusEnum.ReadyForReview }}
          </v-chip>
          <v-chip filter label :value="statusEnum.Reviewed">
            {{ statusEnum.Reviewed }}
          </v-chip>
          <v-chip filter label :value="statusEnum.AwaitingSubmission">
            {{ statusEnum.AwaitingSubmission }}
          </v-chip>
        </v-chip-group>

        <v-data-table :loading="loading" :headers="headers" :items="filteredSessions" :items-per-page.sync="itemsPerPage" 
        @update:items-per-page="(value) => (itemsPerPage = value)" item-key="id" class="notes-section mt-3">
          <template v-slot:top>
            <v-toolbar flat color="#FFF" class="rounded-toolbar">
              <v-text-field class="ml-6 custom-bg" v-model="search" prepend-icon="mdi-magnify" label="Search patient"
                base-color="#fff" single-line hide-details variant="solo-filled" clearable flat></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr @click="onRowClick(item)">
              <td>
                <div>
                  <v-icon v-if="item.notesStatus == statusEnum.Reviewed" color="green" size="12">mdi-circle</v-icon>
                  <v-icon v-else-if="item.notesStatus == statusEnum.ReadyForReview" color="rgb(255, 114, 96)"
                    size="12">mdi-circle-outline</v-icon>
                  <v-icon v-else-if="item.notesStatus == statusEnum.AwaitingSubmission" color="var(--melon)"
                    size="12">mdi-circle-outline</v-icon>
                  <v-icon v-else color="green" size="12">mdi-circle</v-icon>
                  {{ item.notesStatus }}
                </div>
              </td>
              <td>
                <div class="d-flex flex-column align-center">
                  <span>{{ item.patientFirstName ? `${item.patientFirstName}${item.patientLastName ? 
                  ` ${item.patientLastName}` : ''}` : item.patientName }}</span>
                  <span>{{ item.patientEmail }}</span>
                </div>
              </td>
              <td>{{ getFormattedDate(item.date) }}</td>
              <td>
                <v-chip v-if="item.type === 'Questionnaire'" color="var(--melon)" variant="flat">Questionaire</v-chip>
                <v-chip v-else color="var(--mint-macaron)" variant="flat">Note</v-chip>
              </td>
              <td>
                <v-icon class="me-2 hover-icon" @click.stop="onPatientNameClick(item)">
                  mdi-account-arrow-right-outline
                </v-icon>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <v-alert icon="mdi-alert" outlined>No available items.</v-alert>
          </template>
        </v-data-table>
      </div>
    </div>

    <Feedback />

  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import getCollection from '@/composables/getCollection'
import { getFunctions, httpsCallable } from "firebase/functions";
import { Timestamp } from "@firebase/firestore";
import Feedback from '@/components/Feedback.vue';

const router = useRouter()
const { error: sessionsError, documents: userSessions } = getCollection('sessions')
const { error: patientsError, documents: userPatients } = getCollection('patients')

const statusEnum = {
  ReadyForReview: 'Ready for Review',
  Reviewed: 'Reviewed',
  AwaitingSubmission: 'Awaiting Submission',
}

const filterStatus = ref(undefined)
const search = ref('')
const typesFilter = ref(['Notes', 'Questionnaires'])
const chipFilter = ref('All')


const headers = [
  { title: 'Note Status', align: 'center', value: 'noteStatus' },
  { title: 'Patient', align: 'center', value: 'patientName', sortable: true },
  { title: 'Time', align: 'center', value: 'date', sortable: true },
  { title: 'Type', align: 'center', value: 'type' },
  { title: 'Patient Chart', align: 'center', value: 'patientChart' },
]

const functions = getFunctions();
const loading = ref(false);
const patientsQuestionnaires = ref([]);

const itemsPerPage = ref(
  localStorage.getItem('globalItemsPerPage')
    ? JSON.parse(localStorage.getItem('globalItemsPerPage'))
    : 10
);

watch(itemsPerPage, (val) => {
  localStorage.setItem('globalItemsPerPage', JSON.stringify(val));
});

onMounted(async () => {
  chipFilter.value = localStorage.getItem('inbox:chipFilter') ? JSON.parse(localStorage.getItem('inbox:chipFilter')) : 'All'
  typesFilter.value = localStorage.getItem('inbox:typesFilter') ? JSON.parse(localStorage.getItem('inbox:typesFilter')): ['Notes', 'Questionnaires']

  loading.value = true
  const getPatientQuestionnaires = httpsCallable(functions, 'getPatientQuestionnaires');
  const { data } = await getPatientQuestionnaires({ patientId: null });
  patientsQuestionnaires.value = data.questionnaires.map(q => {
    return {
      ...q,
      reviewed: q.reviewed ? 'Reviewed' : q.submitted ? 'Ready for Review' : 'Awaiting Submission',
      date: new Timestamp(q.createdAt._seconds, q.createdAt._nanoseconds)
    }
  })
  loading.value = false;
})

const sortedCombinedSessions = computed(() => {
  if (!userPatients.value?.length) return []
  if (!userSessions.value?.length && !patientsQuestionnaires.value?.length) return []

  const combinedSessions = [
    ...userSessions.value.filter(session => userPatients.value?.some(patient => patient.id === session.patientId) && typesFilter.value.includes('Notes'))
      .map(session => ({
        ...session,
        patientName: userPatients.value.find(patient => patient.id === session.patientId).patientName,
        patientFirstName: userPatients.value.find(patient => patient.id === session.patientId).patientFirstName,
        patientLastName: userPatients.value.find(patient => patient.id === session.patientId).patientLastName,
        patientEmail: userPatients.value.find(patient => patient.id === session.patientId).email,
        type: 'Session'
      })),
    ...patientsQuestionnaires.value.filter(() => typesFilter.value.includes('Questionnaires')).map(questionnaire => ({
      ...questionnaire,
      notesStatus: questionnaire.reviewed,
      type: 'Questionnaire'
    }))
  ].sort((a, b) => b.date - a.date);

  if (filterStatus.value) return combinedSessions.filter(item => item.notesStatus === filterStatus.value)

  return combinedSessions
})

const filteredSessions = computed(() => {
  if (!search.value) return sortedCombinedSessions.value

  const searchTerm = search.value.toLowerCase()
  return sortedCombinedSessions.value?.filter(item => {
    return (
      (item.patientName && item.patientName.toLowerCase().includes(searchTerm)) ||
      (item.patientFirstName && item.patientFirstName.toLowerCase().includes(searchTerm)) ||
      (item.patientLastName && item.patientLastName.toLowerCase().includes(searchTerm)) ||
      (item.notesStatus && item.notesStatus.toLowerCase().includes(searchTerm)) ||
      (item.type && item.type.toLowerCase().includes(searchTerm)) ||
      (item.id && item.id.toLowerCase().includes(searchTerm))
    )
  })
})


watch(chipFilter, (val) => {
  filterStatus.value = val === 'All' ? undefined : val
  localStorage.setItem('inbox:chipFilter', JSON.stringify(val))
})

watch(typesFilter, val => {
  localStorage.setItem('inbox:typesFilter', JSON.stringify(val))
})


const onRowClick = (item) => {
  if (item.type == 'Questionnaire') {
    if (item.patientId) {
      router.push({ name: 'PatientQuestionnaire', params: { patientId: item.patientId, patientQuestionnaireId: item.id } })
    } else {
      router.push({ name: 'PatientQuestionnaire', params: { patientId: 'unassigned-patient', patientQuestionnaireId: item.id } })
    }

    return
  }
  router.push({ name: 'SessionDetails', params: { patientId: item.patientId, id: item.id } })
}

const onPatientNameClick = (item) => {
  router.push({ name: 'PatientOverview', params: { id: item.patientId } })
}

const getFormattedDate = (date) => {
  const formattedDate = new Date(date.seconds * 1000)
    .toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    })
  return formattedDate
}
</script>

<style scoped>
.app-logo {
  max-width: 50%;
  height: auto;
}

.v-data-table {
  border-radius: 14px;
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.session-notes {
  gap: 80px;
}

.session-link {
  color: black;
  transition: transform 0.2s ease-in-out;
  background-color: white;
  padding: 3%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.session-link:hover {
  transform: scale(1.02);
  text-decoration: none;
}

.details {
  margin-right: 50px;
}

tr {
  height: 70px !important;
  cursor: pointer;
}

tr:hover {
  background-color: #e5e6e8;
  /* Adjust the color and opacity as needed */
}

.hand-cursor {
  cursor: pointer;
}

.feedback-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.hover-icon:hover {
  color: #FF7260;
  /* Replace with your desired hover color */
}

.notes-section {
  border-radius: 14px;
  padding: 4px;
  line-height: 1.5;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}


@media (max-width: 768px) {

  /* Adjust the max-width as needed for your design */
  .session-notes {
    grid-template-columns: 1fr;
    /* Single column for smaller screens */
    gap: 20px;
  }

  /* Additional responsive adjustments can be made here if needed */
}

@media (max-width: 768px) {

  /* Adjust this breakpoint as needed */
  .d-flex {
    flex-direction: column;
    /* Stacks flex items vertically */
  }

  /* Optionally, you can add some margin to the top of the AudioRecorder component
       for better spacing when it's below the "Patient Sessions" heading */
  .d-flex>AudioRecorder,
  /* This targets the AudioRecorder component directly, assuming it's the only component of its type within .d-flex */
  .d-flex>[component-name="AudioRecorder"] {
    /* Alternative selector if the first one doesn't work */
    margin-top: 20px;
    /* Adjust the spacing as needed */
  }
}
</style>