<template>
    <div class="d-flex flex-column">
        <v-combobox :readonly="preview" :label="`Section`" v-model="element.item" maxlength="100" color="accent"
            :items="sections"></v-combobox>
        <v-select :readonly="preview" label="Style" color="accent" v-model="element.style"
            :items="['Flow text', 'Bullet points']" />
        <v-select :readonly="preview" v-if="newFeatureEnabled" label="Level of detail" color="accent"
            v-model="element.level" :items="['Concise', 'Standard', 'Detailed']" />
        <v-textarea :readonly="preview" class="exclude-this-item" rows="4" v-model="element.dlevel"
            label="Your Instructions" auto-grow
            placeholder="Give mdhub instructions on how to write this note e.g. extract direct quotes, comment on mood and anxiety levels..."
            persistent-placeholder density="compact" />
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { TEMPLATE_SECTIONS } from '../../shared/constants';

const newFeatureEnabled = JSON.parse(localStorage.getItem('whitelistedFeatures'))?.includes('notes-detail-level');

const props = defineProps({
    element: Object,
    preview: {
        type: Boolean,
        default: false
    }
})

const sections = TEMPLATE_SECTIONS
</script>