<template>
  <div class="d-flex flex-row mb-6">
    <input type="text" v-model="searchQuery" placeholder="Search..." class="search-bar" />
    <v-btn class="mdhub-btn" elevation="0" :to="{ name: 'CreatePatient' }">Create New Patient</v-btn>
  </div>

  <div v-if="filteredPatients.length > 0">

    <div v-for="(patient, index) in filteredPatients" :key="patient.id">
      <router-link :to="{ name: 'PatientOverview', params: { id: patient.id } }" style="text-decoration:none;">
        <div class="session pa-8 mb-1 d-flex flex-row rounded-lg">
          <div class="thumbnail">
            <img class="patient-url" :src="getPatientPicture(patient.patientUrl)">
          </div>

          <div class=" ml-8">
            <h3 class="d-flex align-start">{{ getPatientFullName(patient) }}</h3>
            <p class="d-flex align-start">{{ getPronouns(patient.gender) }}</p>
          </div>
          <div class="d-flex flex-grow-1 justify-end">
            <p class="text-right">{{ patientSessions[index] }} sessions</p>
          </div>

        </div>
      </router-link>
    </div>
  </div>
  <div v-else>
    <ModalOnboarding v-if="!counter && !patients || !patients.length" :isModalOpen="isModalOpen"
      :clinicId="userObject?.clinicId" @close-modal="handleModalClose" />
    <div class="mt-16">
      <h1>No note yet?</h1>
      <p class="mt-6">Start creating a patient and start recording from the web browser</p>
      <p class="mt-6">or</p>
      <p class="mt-6">Download mdhub's mobile app</p>

      <div class="row">
        <!-- Apply class 'col-md-6' to make each image take half the width on medium and larger screens -->
        <div class="mt-4 col-md-6 d-flex justify-center">
          <a href="https://apps.apple.com/us/app/mdhub/id6449866756?l=en-GB">
            <v-img :src="require('@/assets/g-play2.png')" width="260" height="62"></v-img>
          </a>
        </div>

        <div class="mt-4 col-md-6 d-flex justify-center">
          <a
            href='https://play.google.com/store/apps/details?id=com.nbox.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <v-img :src="require('@/assets/g-play.png')" width="250" height="60"></v-img>
          </a>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import ModalOnboarding from '@/components/ModalOnboarding.vue';
import countCollection from '@/composables/countCollection.js'
import defaultImage from '@/assets/gender-neutral.png'
import { getPatientFullName } from '@/utilities/patientUtils'


export default {
  props: ['patients'],
  components: {
    ModalOnboarding,
  },
  methods: {

    getPatientPicture(patientUrl) {
      if (patientUrl === "") {
        return defaultImage
      } else {
        return patientUrl
      }
    },
    openModal() {
      this.isModalOpen = true;
    },
    getPronouns(gender) {
      var pronouns = ""
      if (gender == "Male") {
        pronouns = "(He/him)"
      } else if (gender == "Female") {
        pronouns = "(She/her)"
      } else if (gender == "Non-binary") {
        pronouns = "(They/them)"
      } else if (gender == "Prefer not to say") {
        pronouns = "(Prefer not to say)"
      } else {
        pronouns = "(Other)"
      }
      return pronouns
    },
    handleModalClose() {
      //this.router.push({ name: "CreatePatient" });
      this.isModalOpen = false;
    },
  },
  data() {
    return {
      isModalOpen: true,
      searchQuery: '',
      instructions: [
        "Step 1: Create a new patient using the web or the mobile application.",
        "Step 2: Add a new session using the web or the mobile application.",
        "Step 3: Start recording the conversation using the mobile application.",
        "Step 4: The transcript will be created once you stop the recording in minutes.",
      ],
    };
  },
  computed: {
    filteredPatients() {
      return this.patients.filter(patient => {
        // Adjust the condition based on your search requirements
        return patient.patientName.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  setup(props) {
    const router = useRouter();
    const patientSessions = ref([]);

    onMounted(async () => {
      for (const patient of props.patients) {
        const count = await countCollection(
          'sessions',
          ['patientId', '==', patient.id]
        ).count;
        patientSessions.value.push(count);
      }
    });

    return { 
      router, 
      patientSessions,
      getPatientFullName
    };
  }
}
</script>

<style scoped>
.session {
  transition: all ease 0.2s;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  background-color: #ffffff;
}

.session:hover {
  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
  transform: scale(1.02);
  transition: all ease 0.2s;
}

.thumbnail {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 10px;
}

.patient-url {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.search-bar {
  padding: 4px 16px;
  border-radius: 20px;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
  background-repeat: no-repeat;
  background-position: 8px center;
  padding-left: 32px;
  color: black;
  width: 100%;
  margin-right: 2%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}

/* Optional: Style the input on focus */
.search-bar:focus {
  outline: none;
  background-color: #ffffff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  margin-right: 2%;
  width: 100%;
}

.btn-solid-sm {
  /* Add the following styles */
  flex-grow: 1;
  min-width: 190px;
  /* Adjust the value as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mdhub-btn:hover {
  text-decoration: none !important;
}

/* Custom styles for the welcome message */
.welcome-container {
  border-radius: 8px;
}

@media (max-width: 600px) {

  .flex-row {
    flex-direction: column !important;
    align-items: center;
  }

  .mdhub-btn {
    margin-top: 10px;
  }
}
</style>
