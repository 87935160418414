<template>
    <div class="mr-15 ml-15 patient-overview">
        <div class="error" v-if="error">{{ error }}</div>
        <div v-if="patient" class="patient-details">
            <div ref="stickySection" class="sticky" :class="{ 'sticky-scrolling': isSticky }"
                :style="isSticky && !isDrawerOpen ? { 'position': 'sticky' } : { 'position': '' }">
                <div class="sticky-content">
                    <div class="d-flex flex-row ml-auto pt-n12 mb-2 justify-space-between align-center"
                        v-if="!isSticky">
                        <div class="d-flex flex-column align-start patient-name-title ml-2">
                            <span class="d-flex align-center justify-center">
                                <h4 contenteditable ref="editPatientName">{{ patient.patientFirstName ?
                                    `${patient.patientFirstName}${patient.patientLastName ?
                                        ` ${patient.patientLastName}` : ''}` : patient.patientName }}</h4>
                                <v-tooltip text="Edit patient">
                                    <template v-slot:activator="{ props }">
                                        <v-btn size="small" v-bind="props" class="white--text text--lighten-1 mb-1"
                                            @click="editDialog = true" variant="text">
                                            <v-icon color="var(--whitesmoke)" dense class="">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                            </span>
                            <div class="d-flex">
                                <h7 class="mr-2">{{ patient.gender }}</h7>
                                <h7 class="mr-2"> | </h7>
                                <h7> {{ returnPatientAge() }} years old </h7>
                                <h7 v-if=patient.raceEthnicity class="mr-2"> &nbsp;|</h7>
                                <h7 v-if=patient.raceEthnicity> {{ patient.raceEthnicity }} </h7>
                                <h7 v-if=getLanguageName(patient.preferredLanguage) class="mr-2"> &nbsp;|</h7>
                                <h7 v-if=getLanguageName(patient.preferredLanguage)> {{
                                    getLanguageName(patient.preferredLanguage) }} </h7>
                            </div>
                            <div>
                                <h7 v-if=patient.email> {{ patient.email }} </h7>
                            </div>
                        </div>

                        <div class="d-flex align-center" v-if="!isSticky">
                            <v-btn size="large" class="white--text text--lighten-1" @click="textMessageDrawer = true"
                                v-if="!isMdhubZoom && canSendSms()" variant="text">
                                <span class="white--text add-btn"> Send text message </span>
                            </v-btn>
                            <v-btn size="large" class="white--text text--lighten-1" @click="emailDrawer = true"
                                v-if="!isMdhubZoom" variant="text">
                                <span class="white--text add-btn"> Send Questionnaire </span>
                            </v-btn>
                            <v-btn size="large" class="white--text text--lighten-1 mr-6" v-if="!isMdhubZoom"
                                @click="uploadHistoryDialog = true" variant="text">
                                <v-icon dense class="add-btn">mdi-plus</v-icon>
                                <span class="white--text add-btn"> Import History </span>
                            </v-btn>
                            <AudioRecorderV2 v-if="true" :patientId="patient.id" :patient="patient" type="full" />



                        </div>
                    </div>
                    <div class="responsive-menu">
                        <div class="tabs-container">
                            <v-tabs v-model="menuOptions" class="patient-chart-options"
                                slider-color="var(--bittersweet)">
                                <v-tab>Diagnosis</v-tab>
                                <v-tab>Medication History</v-tab>
                                <v-tab>Sessions</v-tab>
                                <v-tab v-if="!isMdhubZoom">Questionnaires</v-tab>
                                <v-tab v-if="!isMdhubZoom">Treatment Plan</v-tab>
                            </v-tabs>
                        </div>
                        <div class="actions-container mr-4" v-if="isSticky">
                            <v-btn size="large" class="white--text text--lighten-1 mr-4" v-if="!isMdhubZoom"
                                @click="uploadHistoryDialog = true" variant="text">
                                <v-icon dense class="add-btn">mdi-plus</v-icon>
                                <span class="white--text add-btn"> Import History </span>
                            </v-btn>
                            <AudioRecorderV2 v-if="true" :patientId="patient.id" :patient="patient" type="full" />


                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="patient-info" id="details-section">
                    <PatientDetails :patientId="patient.id" />
                </div>
                <div id="medications">
                    <PatientMedication v-if="patient && sessions && !isMdhubZoom" :patientSessions="sessions"
                        :patientId="patient.id" :patientMedicalHistory="patient.patientMedicalHistory">
                    </PatientMedication>
                </div>

                <v-card id="sessions" class="mt-3 sessions-section-card" elevation="0">
                    <div class="d-flex mt-4 ml-5">
                        <div class="d-flex flex-row me-auto text-h6">Sessions</div>
                        <!-- <AudioRecorder :patientId="patient.id" :style="'transparent'" /> -->
                    </div>
                    <v-divider />
                    <v-data-table :headers="headers" :items="sortedSessions" :items-per-page.sync="itemsPerPage"
                        @update:items-per-page="(value) => (itemsPerPage = value)" item-key="id"
                        class="sessions-section">

                        <template v-slot:item="{ item, index }">
                            <tr @click="onRowClick(item)" class="center-align">
                                <td> {{ getFormattedDate(item.date) }}</td>
                                <td>
                                    <div v-if="index > 0">
                                        {{ getDaysBetweenSessions(item.date, sessions[index - 1].date) }} days since
                                        last
                                        session
                                    </div>
                                    <div v-else>Last session</div>
                                </td>
                                <td>
                                    <div>
                                        <v-icon v-if="sessions[index]?.notesStatus == statusEnum.Reviewed" color="green"
                                            size="12">mdi-circle</v-icon>
                                        <v-icon v-else-if="sessions[index]?.notesStatus == statusEnum.ReadyForReview"
                                            color="rgb(255, 114, 96)" size="12">mdi-circle-outline</v-icon>
                                        <v-icon v-else color="green" size="12">mdi-circle</v-icon>
                                        {{ sessions[index]?.notesStatus }}

                                    </div>

                                </td>
                                <td>
                                    <v-btn icon elevation="0"
                                        @click.stop="promptToDeleteSession($event, index, item.id, item.date)"
                                        :disabled="isDeleteDisabled(item.state)">
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>

                                </td>
                            </tr>
                        </template>
                        <template v-slot:no-data>
                            <v-alert icon="mdi-alert" outlined>No sessions have been added to this patient
                                yet.</v-alert>
                        </template>
                    </v-data-table>
                </v-card>


                <div id="questionnaires" v-if="!isMdhubZoom">
                    <PatientQuestionnaires :patient="patient" />

                </div>

                <div id="treatment-plan" class="mb-3">
                    <TreatmentPlanTable :patient="patient" :sessions="sortedSessions" />
                </div>

                <v-tooltip text="Go to top">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" class="d-flex align-left" elevation="0" @click="scrollToItem(null)">
                            <v-icon>mdi-arrow-up</v-icon>
                        </v-btn>
                    </template>
                </v-tooltip>
            </div>



        </div>
        <v-bottom-sheet v-if="!isMdhubZoom" v-model="isPhone">
            <v-card>
                <v-card-title class="headline text-center">Get Our Mobile App</v-card-title>
                <v-card-subtitle class="text-center text-subtitle-1" style="padding: 8px;text-overflow: clip;">
                    Enjoy a smoother experience with our mobile app.
                </v-card-subtitle>
                <!-- Apple Store Link for iOS Devices -->
                <v-card-actions v-if="isIOS" class="justify-center">
                    <v-img :src="require('@/assets/g-play2.png')" @click.native="openAppleStore" class="mr-2 app-logo"
                        contain style="cursor: pointer;"></v-img>
                </v-card-actions>
                <!-- Google Play Store Link for Android Devices -->
                <v-card-actions v-if="isAndroid" class="justify-center">
                    <v-img :src="require('@/assets/g-play.png')" @click.native="openGooglePlayStore"
                        class="mr-2 app-logo" contain style="cursor: pointer;"></v-img>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>

        <v-snackbar color="gray" :timeout="6000" v-model="cannotDeleteSnackbar">You are currently recording for this
            session so
            it cannot be deleted</v-snackbar>
    </div>
    <Search v-if="!isMdhubZoom" @search="handleSearch" :patientId="patientId"></Search>
    <div class="feedback-container" v-if="!isPhone && !isMdhubZoom">
        <Feedback :userEmail="user.email" />
    </div>
    <v-snackbar color="gray" :timeout="6000" v-model="notAdminSnackbar">Please contact your clinic admin to delete this
        patient.</v-snackbar>

    <v-navigation-drawer class="fullscreen-drawer" persistent temporary location="right" :width="800" :scrim="true"
        v-model="uploadHistoryDialog">
        <v-card height="100%">
            <div class="d-flex flex-column" style="height: 100%; overflow-y: auto;">
                <UploadPatientHistory @close-dialog="uploadHistoryDialog = false" :patientId="patientId" />
            </div>
        </v-card>
    </v-navigation-drawer>
    <div class="spacer"></div>
    <v-navigation-drawer class="fullscreen-drawer" persistent temporary location="right" :width="800" :scrim="true"
        v-model="tryToDeleteSession" max-width="500" elevation="0">
        <v-card height="100%" style="z-index: 10000;" class="text-start pa-5">
            <h5>Are you sure you want to delete this session from {{ getFormattedDate(currentSessionDate) }} ?</h5>

            <p>
                This action permanently deletes all data associated with the session,
                making
                it
                non-retrievable.
            </p>
            <v-card-actions class="mb-2">
                <v-spacer></v-spacer>
                <v-btn class="mdhub-btn" variant="text" :disabled="isDeletetingSession"
                    @click="closeDeleteWindow">Cancel</v-btn>
                <v-btn color="blue-darken-1" width="200" variant="text" @click="handleClick($event)"
                    :loading="isDeletetingSession">
                    <span style="text-transform: none;">Delete</span></v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="emailDrawer" temporary location="right" :width="800" :scrim="false">
        <QuestionnaireEmail :patient="patient" :selectQuestionnaire="true" @emailSent="emailDrawer = false;" />
    </v-navigation-drawer>
    <v-navigation-drawer v-if="patient" class="fullscreen-drawer" persistent temporary location="right" :width="800"
        :scrim="false" v-model="editDialog" max-width="500px">
        <v-card style="height: 100%; overflow-y: auto; width: 100%;">

            <v-card-title class="d-flex text-start align-start mt-6 ml-7">
                <span class="text-h5">
                    Edit {{ patient.patientFirstName ?
                        `${patient.patientFirstName}${patient.patientLastName ?
                            ` ${patient.patientLastName}` : ''}` : patient.patientName }}'s details
                </span>
                <v-btn icon class="absolute-top-right mr-4 mt-2" @click="editDialog = false" elevation="0">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form class="d-flex flex-column border-0" style="box-shadow: none; max-width: 100% !important;"
                    @submit.prevent>

                    <template v-if="patient.patientFirstName">
                        <v-text-field class="mt-8" v-model="patient.patientFirstName" color="accent" label="First Name"
                            variant="underlined" density="compact"></v-text-field>
                        <v-text-field class="left-aligned-hint" v-model="patient.patientLastName" color="accent"
                            label="Last Name" variant="underlined" density="compact" hint="Optional"
                            persistent-hint></v-text-field>
                    </template>

                    <template v-else>
                        <v-text-field class="mt-8" v-model="patient.patientName" color="accent" label="Name"
                            variant="underlined" density="compact"></v-text-field>
                    </template>

                    <div class="d-flex align-center justify-center">
                        <v-select label="Gender" v-model="patient.gender" variant="underlined" color="accent"
                            :menu-props="{ zIndex: 99999 }"
                            :items="['Male', 'Female', 'Non-binary', 'Prefer not to say', 'Other', 'Unknown']"></v-select>
                        <v-select class="ml-5" label="Pronouns" v-model="patient.pronoun" variant="underlined"
                            :menu-props="{ zIndex: 99999 }" color="accent"
                            :items="['he/him', 'she/her', 'they/them']"></v-select>
                    </div>

                    <v-select label="Preferred patient language" v-model="patient.preferredLanguage"
                        item-title="display" item-value="code" variant="underlined" color="accent"
                        :menu-props="{ zIndex: 99999 }" :items="languages"></v-select>

                    <v-text-field placeholder="Age" v-model="patient.age" class="mt-4" color="accent"
                        variant="underlined" density="compact" type="number">
                    </v-text-field>
                    <v-select label="Race/Ethnicity" v-model="patient.raceEthnicity" variant="underlined" color="accent"
                        :items="['American Indian or Alaska Native', 'Asian', 'Black or African American', 'Hispanic or Latino', 'Native Hawaiian or Other Pacific Islander', 'White', 'Two or More Races', 'Other']"
                        :menu-props="{ zIndex: 99999 }" hint="Optional" persistent-hint></v-select>
                    <v-text-field label="Email" color="accent" class="left-aligned-hint mt-3" v-model="patient.email"
                        variant="underlined" hint="Optional" persistent-hint type="email"></v-text-field>
                    <v-text-field label="Patient Notes" color="accent" class="left-aligned-hint mt-3"
                        v-model="patient.diagnosis" variant="underlined" hint="Optional" persistent-hint></v-text-field>

                    <div class="d-flex justify-center my-6">
                        <v-btn class="mdhub-btn" :loading="editPatientLoading" @click="editPatient()"
                            elevation="0">Save</v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
    <v-navigation-drawer v-model="textMessageDrawer" temporary location="right" :width="800">
        <div class="d-flex flex-column pa-10" v-if="patient">
            <h5 class="text-left">Send text message to {{ patient.patientFirstName ?
                `${patient.patientFirstName}${patient.patientLastName ?
                    ` ${patient.patientLastName}` : ''}` : patient.patientName }}</h5>
            <v-text-field class="mt-2" label="Patient's phone number" v-model="textMessage.phoneNumber"></v-text-field>
            <v-textarea label="Text" v-model="textMessage.text"></v-textarea>
            <p class="text-left"> {{ sendTextErrorMessage }}</p>
            <v-btn color="bittersweet" elevation="0" @click="sendText" :loading="sendingText">
                <span class="white--text">Send</span>
            </v-btn>
        </div>
    </v-navigation-drawer>
</template>

<script>

import getDocument from '@/composables/getDocument'
import getUser from '@/composables/getUser'
import { computed } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import getCollection from '@/composables/getCollection'
import moment from 'moment';
import { ref, nextTick, watch, onMounted, onUnmounted } from 'vue';
import { getFunctions, httpsCallable } from "firebase/functions";
import PatientDetails from '@/views/patients/PatientDetails.vue'
import Feedback from '@/components/Feedback.vue'
import AudioRecorder from '@/components/AudioRecorder.vue';
import AudioRecorderV2 from '@/components/AudioRecorderV2.vue'
import { trackEvent, DELETE_SESSION, LANGUAGE_SELECTED } from '@/utilities/analyticsService';
import Search from '@/components/Search.vue';
import ChatInterface from '@/components/ChatInterface.vue';
import { getUserActionState, UserActions } from '@/composables/getUserActionState';
import { getIsZoomApp } from '@/composables/configureZoom'
import UploadPatientHistory from '@/components/UploadPatientHistory.vue'
import PatientQuestionnaires from '@/components/questionnaires/PatientQuestionnaires.vue'
import TreatmentPlanTable from '@/components/treatment-plan/TreatmentPlanTable.vue'
import PatientMedication from '@/views/patients/PatientMedication.vue'
import QuestionnaireEmail from '@/components/questionnaires/QuestionnaireEmail.vue'
import useDocument from '@/composables/useDocument'
import { Timestamp } from "@firebase/firestore";
import { sendSmsWhitelist } from '@/composables/isWhitelistedAccount'
import { useRecorderStore } from '@/store/recorder'
import { storeToRefs } from 'pinia'
import getCollectionGlobal from '@/composables/getCollectionGlobal'
import { getUserObject } from '@/composables/getUserObject'

export const availablePatientLanguages = [
    { code: null, display: 'Detect language' },
    { code: 'en-US', display: 'English (US)' },
    { code: 'en', display: 'English (Global)' },
    { code: 'es', display: 'Spanish' },
    { code: 'de', display: 'German' },
    { code: 'fr', display: 'French' },
    { code: 'pt', display: 'Portuguese' },
    { code: 'it', display: 'Italian' },
    { code: 'nl', display: 'Dutch' },
    { code: 'ru', display: 'Russian' },
    { code: 'hi', display: 'Hindi' }
];

export default {
    props: ['id'],
    computed: {
        sortedSessions() {
            if (this.sessions) {
                return this.sessions.sort((a, b) => b.date - a.date);
            } else {
                return [];
            }
        },
    },
    data() {
        return {
            showChat: false,
            searchTerm: '',
            headers: [
                {
                    title: 'Date',
                    align: 'center',
                    sortable: true,
                    value: 'date',
                },
                {
                    title: 'Days Since Last Session',
                    align: 'center',
                    value: 'daysSinceLast',
                },
                {
                    title: 'Note status',
                    align: 'center',
                    value: 'notesStatus',
                },
                {
                    title: 'Delete',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                },
            ],
            newFeatureEnabled: JSON.parse(localStorage.getItem('whitelistedFeatures'))?.includes('last-name'),
            languages: availablePatientLanguages
        };
    },
    components: {
        PatientDetails,
        Feedback,
        AudioRecorder,
        AudioRecorderV2,
        Search,
        ChatInterface,
        UploadPatientHistory,
        PatientQuestionnaires,
        PatientMedication,
        QuestionnaireEmail,
        TreatmentPlanTable
    },
    methods: {
        openGooglePlayStore() {
            let url = 'intent://details?id=com.nbox.app#Intent;scheme=market;package=com.android.vending;end';
            window.location.href = url;
        },
        openAppleStore() {
            window.open('https://apps.apple.com/us/app/mdhub/id6449866756', '_blank');
        },
        handleSearch(term) {
            this.searchTerm = term;
            this.showChat = true;
        },
        onRowClick(item) {
            this.$router.push({ name: 'SessionDetails', params: { patientId: this.patient.id, id: item.id } });
        },
        getFormattedDate(date) {
            //return new Date(date.seconds * 1000)
            const formattedDate = new Date(date.seconds * 1000)
                .toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    //second: "numeric",
                    //timeZoneName: "short"
                });
            return formattedDate
        },
        getDOB(date) {
            if (date) {
                return moment(String(date)).format('DD- MM -YYYY')
            } else {
                return ""
            }
        },
        getDaysBetweenSessions(date1, date2) {
            const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
            const sessionDate1 = new Date(date1.seconds * 1000);
            const sessionDate2 = new Date(date2.seconds * 1000);
            const timeDifference = Math.abs(sessionDate2 - sessionDate1);
            const daysDifference = Math.round(timeDifference / millisecondsPerDay);

            return daysDifference;
        }

    },
    setup(props) {
        const store = useRecorderStore();
        const { recording, sessionId: currentRecordingSessionId } = storeToRefs(store)
        const { error, document: patient } = getDocument('patients', props.id)
        const { user } = getUser()
        const router = useRouter()
        const isPendingSave = ref(false)
        const isDeletetingSession = ref(false)
        const patientId = ref(props.id)
        const screenWidth = ref(window.innerWidth);
        const isPhone = ref(screenWidth.value < 600);
        const isIOS = ref(false);
        const isAndroid = ref(false);
        const indexClicked = ref(undefined);
        const { isZoomApp } = getIsZoomApp();
        const uploadHistoryDialog = ref(false);
        const menuOptions = ref(null);
        const statusEnum = {
            ReadyForReview: 'Ready for Review',
            Reviewed: 'Reviewed'
        };

        const isMdhubZoom = isZoomApp.value;
        const stickySection = ref(null);
        const isSticky = ref(false);
        const isDrawerOpen = ref(false);
        const editDialog = ref(false);
        const editPatientLoading = ref(false);
        const temporaryAge = ref(null);
        const toggleBottomSheet = () => {
            isPhone.value = !isPhone.value;
        };

        const { documents: sessions } = getCollectionGlobal(
            'sessions',
            ['patientId', '==', props.id]
        )

        const tryToDeleteSession = ref(false);
        const currentSessionId = ref(null);
        const currentSessionDate = ref({})

        const itemsPerPage = ref(
            localStorage.getItem('globalItemsPerPage')
                ? JSON.parse(localStorage.getItem('globalItemsPerPage'))
                : 10
        );

        const ownership = computed(() => {
            return patient.value && user.value && user.value.uid == patient.value.userId
        })

        const handleEdit = async () => {

            isPendingSave.value = true
            router.push({ name: 'PatientDetails', params: { patientId: props.id } })

            isPendingSave.value = false
        }

        const cannotDeleteSnackbar = ref(false)
        const notAdminSnackbar = ref(false);

        const promptToDeleteSession = (event, index, sessionId, date) => {
            console.log('event', index)

            if (sessionId === currentRecordingSessionId.value) {
                cannotDeleteSnackbar.value = true
                return
            }

            if (patient.value.clinicId && !userObject.value.isAdmin) {
                notAdminSnackbar.value = true;
                return
            }
            tryToDeleteSession.value = true
            indexClicked.value = index
            currentSessionId.value = sessionId
            currentSessionDate.value = date
            console.log('currentSessionDate', currentSessionDate.value)
            handleDrawerOpen();
            event.stopPropagation();
        }

        const closeDeleteWindow = () => {
            handleDrawerOpen();
            tryToDeleteSession.value = false
            currentSessionId.value = null
            indexClicked.value = null
            isDeletetingSession.value = false
        }

        const handleClick = async (event) => {
            event.stopPropagation();
            const sessionId = currentSessionId.value;
            console.log({ userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId })
            trackEvent(DELETE_SESSION, { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId })
            isDeletetingSession.value = true
            const functions = getFunctions();
            const deleteSessionAndAudioBySessionId = httpsCallable(functions, 'deleteSessionAndAudioBySessionId');
            try {
                console.log('here:', sessionId)
                const result = await deleteSessionAndAudioBySessionId(sessionId)
                console.log('after deleting')
            } catch (error) {
                console.log(`Error deleting Session and Audio ${error}`)
            } finally {
                closeDeleteWindow()
            }

        }

        const isDeleteDisabled = (sessionState) => {
            return !getUserActionState(UserActions.SessionDeletion, sessionState)
        }

        function detectDeviceAndBrowser() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Detecting iOS devices
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                console.log('Device: iOS');
                isIOS.value = true;
                isAndroid.value = false;
            } else if (/android/i.test(userAgent)) {
                // Detecting Android devices
                console.log('Device: Android');
                isAndroid.value = true;
                isIOS.value = false;
            } else {
                console.log('Device: Not iOS or Android');
                isIOS.value = false;
                isAndroid.value = false;
            }

            // Detecting browsers
            if (/chrome/i.test(userAgent)) {
                console.log('Browser: Chrome');
            } else if (/safari/i.test(userAgent) && !/chrome/i.test(userAgent)) {
                // Additional check for Chrome because Chrome's userAgent also contains 'Safari'
                console.log('Browser: Safari');
            } else {
                console.log('Browser: Not Chrome or Safari');
            }
        }

        detectDeviceAndBrowser();

        const scrollToItem = (tab) => {
            const sections = [
                'details-section',
                'medications',
                'sessions',
                'questionnaires',
                'treatment-plan'
            ];
            nextTick(() => {
                const element = tab === null ? document.getElementById('app') : document.getElementById(sections[tab]);
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center',
                })
            })

        }

        watch(menuOptions, (newTab) => {
            if (newTab) {
                scrollToItem(newTab)
            } else {
                scrollToItem(null)
            }

        })

        const userObject = ref({});
        onMounted(async () => {
            window.addEventListener('scroll', checkSticky);
            checkSticky();
            userObject.value = await getUserObject();
        });

        onUnmounted(() => {
            window.removeEventListener('scroll', checkSticky);
        });

        const checkSticky = () => {
            if (isDrawerOpen.value) return;
            if (stickySection.value) {

                if (window.scrollY > 50) {
                    isSticky.value = true;
                    return;
                }
                if (window.scrollY === 0) {
                    isSticky.value = false;
                }
            }
        }

        const handleDrawerOpen = () => {
            isDrawerOpen.value = !isDrawerOpen.value;
            if (isDrawerOpen.value) {
                document.body.classList.add('no-scroll');
            } else {
                isSticky.value = false;
                document.body.classList.remove('no-scroll');
            }
        }

        const returnPatientAge = () => {
            temporaryAge.value = patient.value.age;
            if (patient.value.age) return patient.value.age;
            if (!patient.value || !patient.value.dob) return "";
            // dob is an object so we need to handle it as such
            const dobSeconds = patient.value.dob.seconds;
            const dobMilliseconds = dobSeconds * 1000 + patient.value.dob.nanoseconds / 1e6; // Convert nanoseconds to milliseconds -> this equals to 1000000
            const dobDate = new Date(dobMilliseconds);
            const age = moment().diff(moment(dobDate), 'years');
            patient.value.age = age;
            temporaryAge.value = patient.value.age;
            return age.toString();
        };

        const emailDrawer = ref(false)

        const editPatient = async () => {
            editPatientLoading.value = true;

            let dobTimestamp
            const age = +patient.value.age

            // If age is provided, calculate dob as January 2 of the corresponding year
            const currentYear = new Date().getFullYear();
            const yearOfBirth = currentYear - patient.value.age;

            const currentDay = new Date().getDate();
            const currentMonth = new Date().getMonth();
            const dob = new Date(yearOfBirth, currentMonth, currentDay);
            dobTimestamp = Timestamp.fromDate(dob);
            const updateDateAndDOB = temporaryAge.value !== age ? {
                dob: dobTimestamp,
                age,
            } : {};
            const { error, updateDocument } = useDocument('patients', patient.value.id);

            const updatedData = {};

            if (patient.value.patientFirstName) {
                updatedData.patientFirstName = patient.value.patientFirstName || '';
                updatedData.patientLastName = patient.value.patientLastName || '';
                updatedData.patientName = `${patient.value.patientFirstName} ${patient.value.patientLastName}`.trim();
            } else {
                updatedData.patientName = patient.value.patientName;
            }

            const language = patient.value.preferredLanguage;
            if (language) {
                trackEvent(LANGUAGE_SELECTED, { language, userId: user.value.uid, userEmail: user.value.email, patientId: patient.value.id });
            }

            const res = await updateDocument({
                preferredLanguage: language || null,
                gender: patient.value.gender || '',
                pronoun: patient.value.pronoun || '',
                diagnosis: patient.value.diagnosis || null,
                raceEthnicity: patient.value.raceEthnicity || null,
                email: patient.value.email || null,
                ...updateDateAndDOB,
                ...updatedData
            })

            editPatientLoading.value = false;
            if (!error.value) {
                editDialog.value = false;
            }
        }

        //sms
        const textMessageDrawer = ref(false)
        const textMessage = ref({
            phoneNumber: '',
            text: ''
        })
        const sendingText = ref(false)
        const sendTextErrorMessage = ref('')

        const canSendSms = () => {
            const isWhitelisted = sendSmsWhitelist();
            return isWhitelisted;
        }

        const sendText = () => {
            if (!textMessage.value.phoneNumber || !textMessage.value.text) return;
            sendingText.value = true;
            sendTextErrorMessage.value = ''
            const functions = getFunctions();
            const sendTextMessage = httpsCallable(functions, 'sendSmsClient');
            sendTextMessage({
                to: textMessage.value.phoneNumber,
                text: textMessage.value.text
            }).then(() => {
                textMessageDrawer.value = false;
            }).catch((error) => {
                console.error('Error sending text message:', error);
                sendTextErrorMessage.value = error.message
            })
                .finally(() => {
                    sendingText.value = false;

                })
        }

        watch(itemsPerPage, (val) => {
            localStorage.setItem('globalItemsPerPage', JSON.stringify(val));
        });

        const getLanguageName = (code) => {
            if (code) {
                const language = availablePatientLanguages.find(lang => lang.code === code);
                return language ? language.display : null;
            } else {
                return null;
            }
        }

        return { itemsPerPage, canSendSms, sendTextErrorMessage, sendText, sendingText, textMessage, textMessageDrawer, notAdminSnackbar, cannotDeleteSnackbar, recording, currentSessionDate, editPatientLoading, editPatient, editDialog, emailDrawer, closeDeleteWindow, tryToDeleteSession, promptToDeleteSession, isDrawerOpen, handleDrawerOpen, isSticky, stickySection, scrollToItem, menuOptions, statusEnum, indexClicked, error, patient, ownership, handleClick, isDeleteDisabled, sessions, isPendingSave, handleEdit, isDeletetingSession, user, patientId, screenWidth, isPhone, toggleBottomSheet, isIOS, isAndroid, isMdhubZoom, uploadHistoryDialog, returnPatientAge, getLanguageName }
    }
}
</script>

<style scoped>
.app-logo {
    max-width: 50%;
    height: auto;
}

.v-data-table {
    border-radius: 14px;
}

.center-align td,
.center-align th {
    text-align: center;
    vertical-align: middle;
}

.button-group {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.patient-details {
    gap: 80px;
}

.patient-info {
    display: flex;
    flex-direction: column;
}

.patient-info h2 {
    text-transform: capitalize;
    font-size: 28px;
    margin-top: 20px;
}

.patient-info p {
    margin-bottom: 20px;
}

.session-link {
    color: black;
    transition: transform 0.2s ease-in-out;
    background-color: white;
    padding: 3%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.session-link:hover {
    transform: scale(1.02);
    text-decoration: none;
}

.details {
    margin-right: 50px;
}

tr {
    height: 70px !important;
    cursor: pointer;
}

tr:hover {
    background-color: #e5e6e8;
    /* Adjust the color and opacity as needed */
}

.hand-cursor {
    cursor: pointer;
}

.feedback-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1001 !important;
}

.sessions-section {
    border-radius: 14px;
    padding: 4px;
    line-height: 1.5;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 0px;
}

.sessions-section-card {
    font-size: 1rem;
    text-align: left;
    position: relative;
    background-color: white;
    padding: 4px;
    margin-top: 3.5%;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}

.recorder-button-container {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    margin-right: 50px;
    z-index: 999;
}

.recorder-btn {
    background-color: var(--bittersweet) !important;
    border-radius: 30px !important;
    color: var(--white) !important;
    flex-grow: 0;
    height: 50px !important;
    width: 200px;
}

.white--text {
    color: white;
    text-transform: none;
}

.add-btn {
    background-color: transparent !important;
    color: var(--bittersweet);
    text-transform: none !important;
    width: auto;
}

.patient-name-title {
    width: 100%;
}

.patient-chart-options ::v-deep .v-tab--selected span {
    color: var(--bittersweet);

}


.sticky {

    top: 65px;
    z-index: 1000;
    padding: 10px 0;

}

.sticky-scrolling {
    background-color: white;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.05);
    border-radius: 14px;


}

.sticky-content {
    width: 100%;
    margin: 0 auto;
}

.patient-name-title {
    flex-grow: 1;
}

.spacer {
    height: 500px;
}

.responsive-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tabs-container {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    /* for smooth scrolling on iOS */
}

.patient-chart-options {
    display: inline-flex;
    min-width: 100%;
}

.actions-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;

}

:global(.no-scroll) {
    overflow: hidden !important;
}

@media (min-width: 1080px) {
    .responsive-menu {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .tabs-container {
        flex-grow: 1;
        overflow-x: visible;
    }

    .actions-container {
        padding-top: 0;
        padding-bottom: 0;
    }
}

@media (max-width: 580px) {

    .actions-container {
        display: block;
    }

    .actions-container .add-btn {
        width: max-content;
    }

}

@media (max-width: 768px) {

    /* Adjust the max-width as needed for your design */
    .patient-details {
        grid-template-columns: 1fr;
        /* Single column for smaller screens */
        gap: 20px;
    }

    /* Additional responsive adjustments can be made here if needed */
}

@media (max-width: 768px) {

    /* Adjust this breakpoint as needed */
    .d-flex {
        flex-direction: column;
        /* Stacks flex items vertically */
    }

    /* Optionally, you can add some margin to the top of the AudioRecorder component
       for better spacing when it's below the "Patient Sessions" heading */
    .d-flex>AudioRecorder,
    /* This targets the AudioRecorder component directly, assuming it's the only component of its type within .d-flex */
    .d-flex>[component-name="AudioRecorder"] {
        /* Alternative selector if the first one doesn't work */
        margin-top: 20px;
        /* Adjust the spacing as needed */
    }
}

.absolute-top-right {
    position: absolute;
    top: 0;
    right: 0;
}

::v-deep(.v-input__details .v-messages__message) {
    position: fixed !important;
    font-size: x-small;
}
</style>