<template>
    <div class="drawer-head">

        <div class="px-8 pt-4">
            <div class="d-flex flex-column text-left mx-2 w-100" v-if="!recording">
                <h2 class="mb-0">Start recording {{ patient?.patientFirstName ? 
                    `${patient?.patientFirstName}${patient?.patientLastName ? ` ${patient?.patientLastName}` : ''}` : patient?.patientName }}</h2>
                <p class="text-caption mb-0">Please record more than 20 sec. for the note to be created</p>
                <hr>
                <h5 class="mb-0">Source</h5>
                <p class="text-caption mb-0">{{ device?.label || 'Unknown Device' }}</p>

                <h5 class="mt-6">Session type</h5>
                <v-btn-toggle v-model="sessionType" color="#eee" mandatory divided>
                    <v-tooltip text="Record a live patient session" location="bottom" z-index="10000">
                        <template v-slot:activator="{ props }">
                            <v-btn v-bind="props" value="live">Live session</v-btn>
                        </template>
                    </v-tooltip>

                    <v-tooltip
                        text="Record yourself, summarizing a past patient visit. No need to specify periods, just speak naturally"
                        location="bottom" z-index="10000">
                        <template v-slot:activator="{ props }">
                            <v-btn v-bind="props" value="dictation">Dictation</v-btn>
                        </template>
                    </v-tooltip>
                </v-btn-toggle>
            </div>
            <div class="d-flex align-center text-left  mx-2 w-100" v-else>
                <h2 class="mb-0">Recording {{ patient?.patientFirstName ? 
                    `${patient?.patientFirstName}${patient?.patientLastName ? ` ${patient?.patientLastName}` : ''}` : patient?.patientName }}</h2>
                <span class="ml-1 " :class="!paused ? 'recording-indicator' : 'error-indicator'"></span>
            </div>
        </div>

        <div class="actions" v-if="!recording">
            <div class="d-flex flex-column flex-md-row align-center w-100 pa-4">
                <v-btn height="50" variant="text" @click="triggerFileInput">
                    <v-icon size="x-large" class="mr-4">mdi-file-upload</v-icon>
                    <span style="text-transform: none">Upload audio</span>
                </v-btn>
                <input type="file" ref="fileInput" @change="handleFileUpload"
                    accept=".mp3, .wav, .aac, .flac, .m4a, .webm" hidden>
                <v-spacer></v-spacer>
                <v-btn class="action-button " v-if="sessionType === 'live'" elevation="0" height="50"
                    @click="startRecordingHeadphones('live')">
                    <v-icon color="bittersweet" prepend-icon dense>mdi-headphones</v-icon>
                    <span class="ml-2 bittersweet-button">With headphones</span>
                </v-btn>

                <v-btn class="mx-3 action-button" v-if="sessionType === 'live'" elevation="0" color="bittersweet"
                    height="50" @click="startRecording('live')">
                    <v-icon size="large" color="white">mdi-headphones-off</v-icon>
                    <span class="ml-3 white--buton"> Without headphones</span>
                </v-btn>

                <v-btn class="ml-auto mx-3 action-button" v-if="sessionType === 'dictation'" elevation="0"
                    color="bittersweet" height="50" @click="startRecording('dictation')">
                    <v-icon size="large" color="white">mdi-microphone</v-icon>
                    <span class="ml-3 white--buton"> Start dictating</span>
                </v-btn>
            </div>
        </div>

        <v-dialog v-model="uploadFailed" width="400" persistent>
            <v-card class="d-flex flex-column align-start pa-5">
                <h5>Problem uploading audio</h5>
                <p>The audio file failed to upload, please try again.</p>
                <v-btn class="ml-auto action-button white--buton" @click="uploadFailed = false">Ok</v-btn>
            </v-card>
        </v-dialog>
    </div>

</template>

<script setup>
import { useRecorderStore } from '@/store/recorder'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { getFunctions, httpsCallable } from "firebase/functions";
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router';
import { trackEvent, RECORDER_CREATE_SESSION, RECORDER_UPLOAD_FILE, RECORDER_UPLOAD_FILE_ERROR } from '@/utilities/analyticsService';
import getNewVersion from '@/composables/getNewVersion';
import { askNotificationPermission } from '@/composables/useNotifications';
import { defineProps } from 'vue';

const props = defineProps({
    selectedTemplate: Object,
    patientQuestionnaireId: Object,
    additionalNotes: String,
    followupUsed: String
})

const router = useRouter();
const { user } = getUser();
const functions = getFunctions();

const store = useRecorderStore();
const { patient, recording, patientId, sessionId, drawer, paused } = storeToRefs(store)

const { uploadAudio, startRecordingMic, startRecordingTab, setSessionId, setOverlay, setUploadingAudio } = store

const startRecording = async (type) => {
    await createSession(type)
    startRecordingMic();
    setOverlay(false)
    askNotificationPermission()

}

const startRecordingHeadphones = async (type) => {
    await createSession(type)
    startRecordingTab();
    setOverlay(false)
    askNotificationPermission()

}

const createSession = async (type) => {
    setOverlay(true)
    const createNewSessionFunction = httpsCallable(functions, 'createSession');
    const payload = { userId: user.value.uid, patientId: patientId.value, transcript: '', type, additionalNotes: '', source: 'web', location: patient.value.address, modality: 'telehealth' };

    const result = await createNewSessionFunction(payload);
    const id = result.data.id;

    setSessionId(id);
    trackEvent(RECORDER_CREATE_SESSION, { userId: user.value.uid, userEmail: user.value.email, payload })

}
const device = ref(null)
const devices = ref([])

onMounted(async () => {
    await updateDevices();
    navigator.mediaDevices.ondevicechange = handleDeviceChange;
})

const isFirefox = computed(() => {
    return /firefox/i.test(navigator.userAgent);
})

const getInputDevice = () => {
    if (isFirefox.value) {
        return devices.value[0]
    }
    return devices.value.find(device => device.deviceId === 'default');
}

const updateDevices = async () => {
    const allDevices = await navigator.mediaDevices.enumerateDevices();
    devices.value = allDevices.filter(device => device.kind === 'audioinput');
    device.value = getInputDevice();
}

const handleDeviceChange = async () => {
    await updateDevices();
}


// file
const fileInput = ref(null);
const uploadFailed = ref(false);

const triggerFileInput = () => {
    fileInput.value.click();
};

const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
        try {
            setUploadingAudio(true)
            await createSession(sessionType.value)
            await submitAudio(file)
            setOverlay(false)
        } finally {
            setUploadingAudio(false)
        }



    }
};

const submitAudio = async (blob) => {
    try {
        const functions = getFunctions();
        const updateSessionData = httpsCallable(functions, 'updateSessionData');
        const updateData = { additionalNotes: props.additionalNotes, templateId: props.selectedTemplate?.id, patientQuestionnaireId: props.patientQuestionnaireId?.id, followupUsed: props.followupUsed };
        await updateSessionData({ userId: user.value.uid, sessionId: sessionId.value, updateData });
    } catch (err) {
        console.log('Update session data failed: ', err.message)
    }

    try {
        console.log("BLOB", blob)
        await uploadAudio(patientId.value, sessionId.value, blob, user.value.uid, user.value.email);
        trackEvent(RECORDER_UPLOAD_FILE, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId.value, sessionId: sessionId.value, blob })
        router.push({ name: "SessionDetails", params: { patientId: patientId.value, id: sessionId.value } });
    } catch (err) {
        console.log('Upload failed: ', err.message)
        uploadFailed.value = true;
        trackEvent(RECORDER_UPLOAD_FILE_ERROR, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId.value, sessionId: sessionId.value, error: { errorName: err.name, errorMessage: err.message, errorStack: err.stack } })
    }
}

const sessionType = ref('live')

watch(drawer, async (newVal) => {
    if (newVal && !recording.value) {
        await getNewVersion();
    }
    sessionType.value = 'live'
})

</script>


<style scoped>
@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

.recording-indicator {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: var(--bittersweet);
    border-radius: 50%;
    animation: pulse 1s infinite;
}

.error-indicator {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: grey;
    border-radius: 50%;
    margin-left: 8px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

.actions {
    position: fixed;
    bottom: 0;
    background-color: white;
    z-index: 10;
    width: 100%;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.action-button {
    text-transform: none;
}

.bittersweet-button {
    color: var(--bittersweet) !important;
}

.white--buton {
    color: white !important;
}
</style>