<template>
    <v-card class="pa-0 mdhub-card">
        <v-row no-gutters>
            <v-col class="d-flex flex-column justify-space-between">
                <div v-if="isDetailsTrue">
                    <v-card-title class="headline mt-5 ml-5 left-align">Start saving time</v-card-title>
                    <v-card-text class="headline mt-5 ml-5 left-align" style="line-height: 2;">The trial is limited to
                        30 sessions.
                    </v-card-text>
                    <v-card-text class="headline ml-5 left-align" style="line-height: 2;">The trial is limited to
                        Upgrade to mdhub Pro for $119/month to unlock unlimited sessions. Cancel anytime.
                    </v-card-text>
                    <v-card-text class="headline ml-5 left-align" style="line-height: 2;">Cancel anytime.
                    </v-card-text>
                </div>
                <div v-else>
                    <v-card-title class="headline mt-5 ml-5 left-align">Continue using mdhub</v-card-title>
                    <v-card-text class="headline mt-5 ml-5 left-align" style="line-height: 2;">
                        <div style="margin-bottom: 16px;">
                            Seems like you reached the 30 free sessions in your trial. To continue with mdhub and keep
                            saving time, upgrade your plan.
                        </div>
                        <div style="margin-bottom: 16px;">
                            If you have any questions around pricing, you can reach us at <a
                                href="mailto:support@mdhub.ai">support@mdhub.ai</a>
                        </div>
                        <div style="margin-bottom: 16px;">
                            If you are a student or a resident and want to keep using mdhub please reach out to <a
                                href="mailto:student@mdhub.ai">student@mdhub.ai</a>
                        </div>
                    </v-card-text>
                </div>
                <v-btn class="mdhub-big-btn ml-8 mb-8 mt-8" elevation="0" @click="createSub">
                    {{ isLoading ? "Loading..." : "Continue using mdhub" }}
                </v-btn>
            </v-col>
            <v-col class="ma-0 pa-0">
                <v-img :src="require('@/assets/mdhub-therapist.jpg')" alt="Banner Image" :aspect-ratio="1"
                    min-height="600" cover class="ma-0 pa-0" />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { onSnapshot, collection, query, where, getDocs, addDoc } from "firebase/firestore";

import getUser from '../composables/getUser'
import { projectFirestore } from '../firebase/config'
import { trackEvent, UPGRADE_PRO, DISPLAY_FORCE_BANNER } from '@/utilities/analyticsService';
import { computed } from 'vue'

export default {
    props: ['details'],
    data() {
        return {
            products: [],
            prices: [],
            selectedPrice: null,
            isLoading: false,
        };
    },
    mounted() {
        this.fetchProducts();
    },
    methods: {

        getFormattedPrice(price, currency, interval) {

            let priceString = price / 100

            if (currency == "usd") {
                priceString = "$ " + priceString
            } else if (currency == "eur") {
                priceString = "e " + priceString
            }
            priceString = priceString + "/" + interval

            return priceString
        },

        async fetchProducts() {
            const productsRef = collection(projectFirestore, "products");
            const productsQuery = query(productsRef, where("active", "==", true));
            const productsQuerySnap = await getDocs(productsQuery);
            productsQuerySnap.forEach(async (doc) => {
                const pricesRef = collection(projectFirestore, "products", doc.id, "prices");
                const pricesQuerySnap = await getDocs(pricesRef);
                this.products.push({
                    id: doc.id,
                    ...doc.data(),
                    prices: pricesQuerySnap.docs
                        .filter((doc) => doc.data().active)  // Filter out inactive prices
                        .map((price) => {
                            return {
                                id: price.id,
                                ...price.data(),
                            };
                        }),
                });
            });
        },
        async createSub() {
            this.isLoading = true;
            this.selectedPrice = this.products[0].prices[0].id
            const { user } = getUser()
            const collectionRef = collection(
                projectFirestore,
                "customers",
                user.value.uid,
                "checkout_sessions"
            );
            trackEvent(UPGRADE_PRO, { userId: user.value.uid, source: this.details ? 'Details' : 'Banner' })
            const docRef = await addDoc(collectionRef, {
                price: this.selectedPrice,
                success_url: window.location.origin + "/thank-you",
                cancel_url: window.location.origin + "/details",
                allow_promotion_codes: true
            });
            onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data();
                if (error) {
                    console.error(`An error occured: ${error.message}`);
                    this.isLoading = false;
                }
                if (url) {
                    window.location.assign(url);
                }
            });
        },
    },
    setup(props) {
        const isDetailsTrue = computed(() => props.details === true);
        const { user } = getUser()

        if (props.details == false) {
            trackEvent(DISPLAY_FORCE_BANNER, { userId: user.value.uid })
        }

        return { isDetailsTrue };
    }
};
</script>
<style scoped>
.Radio {
    display: inline-flex;
    align-items: center;
}

.Radio-Input {
    margin: 0 0.5em 0;
}

v-img {
    border-radius: 0px !important;
}

.left-align {
    text-align: left;
}

.mdhub-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.left-align-btn {
    text-align: left;
    display: block;
}
</style>