<template>
    <div class="d-flex flex-column align-start py-4">
        <v-autocomplete v-model="doctorId" :loading="gettingDoctors" class="select" label="Select a team member"
            :items="doctors" item-title="email" item-value="userId" clearable>
            <template v-slot:no-data>
                <div class="pa-3" v-if="gettingDoctors">
                    Loading users...
                </div>
                <div class="pa-3" v-else>No data available</div>
            </template>
        </v-autocomplete>
        <v-data-table class="notes-section" :hover="true" :loading="loading" :headers="headers" :items="filteredInbox">
            <template v-slot:top>
                <v-toolbar flat color="#FFF" class="rounded-toolbar">
                    <v-text-field class="ml-6 custom-bg" v-model="search" prepend-icon="mdi-magnify" label="Search"
                        base-color="#fff" single-line hide-details variant="solo-filled" clearable flat></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn :loading="batchDownloading" color="primary" @click="downloadAllNotes"
                        prepend-icon="mdi-download-multiple">
                        Download All Notes
                    </v-btn>
                </v-toolbar>
                <v-dialog v-model="showProgressDialog" persistent width="400">
                    <v-card>
                        <v-card-text>
                            <p class="text-center mb-4">Generating Clinical Notes PDF</p>
                            <v-progress-linear :model-value="downloadProgress" color="primary" height="25" striped>
                                <template v-slot:default="{ value }">
                                    <strong>{{ Math.ceil(value) }}%</strong>
                                </template>
                            </v-progress-linear>
                            <p class="text-center mt-4 text-body-2">
                                {{ downloadProgress === 0 ? 'Starting process...' :
                                    downloadProgress < 100 ? `Processing PDFs (${downloadProgress}%)`
                                        : 'Files ready for download' }} </p>
                        </v-card-text>
                        <v-card-actions v-if="downloadProgress === 100">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="downloadZip" prepend-icon="mdi-download">
                                Download Files
                            </v-btn>
                            <v-btn color="gray" variant="text" @click="closeDialog">
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </template>
            <template v-slot:item="{ item }">

                <tr @click="rowClick(item)">
                    <td class="text-center">
                        <div>
                            <v-icon v-if="item.status == statusEnum.Reviewed" color="green"
                                size="12">mdi-circle</v-icon>
                            <v-icon v-else-if="item.status == statusEnum.ReadyForReview" color="rgb(255, 114, 96)"
                                size="12">mdi-circle-outline</v-icon>
                            <v-icon v-else-if="item.status == statusEnum.AwaitingSubmission" color="var(--melon)"
                                size="12">mdi-circle-outline</v-icon>
                            <v-icon v-else color="green" size="12">mdi-circle</v-icon>
                            {{ item.status }}
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-column text-center align-center">
                            <span>{{ item.patient?.patientFirstName ? `${item.patient?.patientFirstName}${item.patient?.patientLastName 
                                ? ` ${item.patient?.patientLastName}` : ''}` : item.patient?.patientName }}</span>
                            <span>{{ item.patient?.email }} </span>
                        </div>
                    </td>
                    <td class="text-center">
                        {{ getFormattedDate(item.createdAt) }}
                    </td>
                    <td class="text-center">
                        <v-chip v-if="item.type === 'Questionnaire'" color="var(--melon)"
                            variant="flat">Questionaire</v-chip>
                        <v-chip v-else color="var(--mint-macaron)" variant="flat">Note</v-chip>
                    </td>
                    <td class="text-center">
                        <v-btn v-if="item.type === 'Session'" icon="mdi-magnify" size="small" variant="text"
                            @click.stop="openSummaryDialog(item)"></v-btn>

                        <v-btn v-if="item.type === 'Session' && item.summary" icon="mdi-download" size="small"
                            variant="text" @click.stop="generatePDF(item)" :loading="downloading"></v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog v-model="showDialog">
            <v-card>
                <v-card-title class="ma-4">
                    {{ selectedPatientName }}
                    <span class="text-subtitle-2 text-grey ml-2">•</span>
                    <span class="text-subtitle-2 text-grey ml-2">{{ selectedDate }}</span>
                </v-card-title>

                <v-card-text>
                    <div class="summary-content" v-html="selectedSummary"></div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" variant="text" @click="showDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import html2pdf from 'html2pdf.js'
import getUser from '@/composables/getUser'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Timestamp } from "@firebase/firestore";
import { useRouter } from 'vue-router';
import { collection, addDoc, onSnapshot, getFirestore, doc } from 'firebase/firestore'


const { user } = getUser()
const functions = getFunctions();
const router = useRouter()

const doctors = ref([])
const gettingDoctors = ref(false)
const doctorId = ref(null)

const showDialog = ref(false)
const selectedSummary = ref('')
const selectedPatientName = ref('')
const selectedDate = ref('')



onMounted(async () => {
    gettingDoctors.value = true
    const getClinicUsers = httpsCallable(functions, 'getClinicUsers');
    const res = await getClinicUsers();

    doctors.value = res.data.users
    gettingDoctors.value = false
})

watch(doctorId, (newVal) => {
    if (!newVal) {
        inbox.value = { questionnaires: [], sessions: [] }
        return
    }
    getDoctorInbox(newVal)
})

const loading = ref(false)
const inbox = ref({ questionnaires: [], sessions: [] })

const getDoctorInbox = async (doctorId) => {
    loading.value = true
    const getClinicInboxByUser = httpsCallable(functions, 'getClinicInboxByUser');
    const res = await getClinicInboxByUser({ doctorId })
    console.log(res)
    inbox.value.questionnaires = res.data.questionnaires
    inbox.value.sessions = res.data.sessions
    loading.value = false
}

const currentProviderEmail = computed(() => {
    if (!doctorId.value || !doctors.value.length) return ''
    const currentDoctor = doctors.value.find(doc => doc.userId === doctorId.value)
    return currentDoctor?.email || ''
})


const generatePDF = async (item) => {

    const noteDate = new Date(item.createdAt._seconds * 1000)
        .toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/\//g, '-')

    const content = document.createElement('div')
    content.innerHTML = `
        <div style="padding: 20px;">
            <h1 style="color: #333; margin-bottom: 20px;">Rula x mdhub</h1>
            
            <div style="margin-bottom: 20px;">
                <h2 style="color: #666;">Patient Information</h2>
                <p><strong>Provider:</strong> ${currentProviderEmail.value || 'N/A'}</p>
                <p><strong>Patient name:</strong> ${item.patient?.patientFirstName ? 
                    `${item.patient.patientFirstName} ${item.patient.patientLastName || ''}`.trim() : item.patient?.patientName || 'N/A'}</p>
                <p><strong>Patient email:</strong> ${item.patient?.email || 'N/A'}</p>
                <p><strong>Date:</strong> ${getFormattedDate(item.createdAt)}</p>
            </div>

            <div style="margin-bottom: 20px;">
                <h2 style="color: #666;">Clinical Note</h2>
                ${item.summary || 'No summary available'}
            </div>
        </div>
    `

    // PDF options
    const options = {
        margin: 1,
        filename: `clinical-note-${item.patient?.patientFirstName
            ? `${item.patient.patientFirstName}${item.patient.patientLastName ? ` ${item.patient.patientLastName}` : ''}`
            : item.patient?.patientName || 'Unknown'}-${noteDate}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    }

    try {
        await html2pdf().from(content).set(options).save()
    } catch (error) {
        console.error('Error generating PDF:', error)
        // Handle error appropriately (e.g., show error message to user)
    }
}


const batchDownloading = ref(false)
const downloadProgress = ref(0)
const showProgressDialog = ref(false)

const db = getFirestore()
const downloadUrl = ref(null)


const downloadAllNotes = async () => {
    try {
        batchDownloading.value = true
        showProgressDialog.value = true
        downloadProgress.value = 0
        downloadUrl.value = null

        const progressDocId = `pdf-progress-${Date.now()}-${user.value.uid}`

        const unsubscribe = onSnapshot(
            doc(db, 'pdfGenerationProgress', progressDocId),
            (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data()
                    downloadProgress.value = data.progress || 0

                    if (data.status === 'error') {
                        console.error('Error:', data.error)
                        alert(`Error: ${data.error}`)
                        unsubscribe()
                        showProgressDialog.value = false
                    } else if (data.status === 'complete') {
                        downloadUrl.value = data.downloadUrl
                        batchDownloading.value = false
                        // Don't automatically close dialog, let user choose when to download
                    }
                }
            },
            (error) => {
                console.error('Error listening to progress:', error)
                alert('Error monitoring progress. Please try again.')
                showProgressDialog.value = false
            }
        )

        const generatePDFs = httpsCallable(functions, 'generateAllProviderPDFs')
        await generatePDFs({ progressDocId })

    } catch (error) {
        console.error('Error downloading notes:', error)
        alert('Error downloading notes. Please try again.')
        showProgressDialog.value = false
    }
}

const downloadZip = () => {
    if (downloadUrl.value) {
        const link = document.createElement('a')
        link.href = downloadUrl.value
        link.download = `clinical-notes-${new Date().toISOString().split('T')[0]}.zip`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
}

const closeDialog = () => {
    showProgressDialog.value = false
    downloadProgress.value = 0
    downloadUrl.value = null
}


const openSummaryDialog = (item) => {
    selectedSummary.value = item.summary || '<p>No summary available</p>'
    selectedPatientName.value = item.patient?.patientFirstName
    ? `${item.patient.patientFirstName}${item.patient.patientLastName ? ` ${item.patient.patientLastName}` : ''}`
    : item.patient?.patientName || 'Unknown Patient';
    selectedDate.value = getFormattedDate(item.createdAt)
    showDialog.value = true
}

const sortedCombinedInbox = computed(() => {
    const combinedInbox = [
        ...inbox.value.questionnaires
            .map(questionnaire => ({
                ...questionnaire,
                type: 'Questionnaire',
                date: new Timestamp(questionnaire.createdAt._seconds, questionnaire.createdAt._nanoseconds),
                status: questionnaire.reviewed ? 'Reviewed' : questionnaire.submitted ? 'Ready for Review' : 'Awaiting Submission',
            })),
        ...inbox.value.sessions
            .map(session => ({
                ...session,
                type: 'Session',
                date: new Timestamp(session.createdAt._seconds, session.createdAt._nanoseconds),
                status: session.notesStatus
            }))
    ].sort((a, b) => b.date - a.date)
    return combinedInbox
})

const search = ref('')
const filteredInbox = computed(() => {
    if (!search.value) return sortedCombinedInbox.value

    const searchTerm = search.value.toLowerCase()
    return sortedCombinedInbox.value.filter(item => {
        return (
            (item.patient?.patientName && item.patient?.patientName.toLowerCase().includes(searchTerm)) ||
            (item.patient?.patientFirstName && item.patient?.patientFirstName.toLowerCase().includes(searchTerm)) ||
            (item.patient?.patientLastName && item.patient?.patientLastName.toLowerCase().includes(searchTerm)) ||
            (item.patient?.email && item.patient?.email.toLowerCase().includes(searchTerm)) ||
            (item.notesStatus && item.notesStatus.toLowerCase().includes(searchTerm)) ||
            (item.type && item.type.toLowerCase().includes(searchTerm)) ||
            (item.id && item.id.toLowerCase().includes(searchTerm))
        )
    })
})


//constants
const statusEnum = {
    ReadyForReview: 'Ready for Review',
    Reviewed: 'Reviewed',
    AwaitingSubmission: 'Awaiting Submission',
}

const headers = [
    { title: 'Status', align: 'center', value: 'status' },
    { title: 'Patient', align: 'center', value: 'patient' },
    { title: 'Time', align: 'center', value: 'date' },
    { title: 'Type', align: 'center', value: 'type' },
    { title: '', align: 'center', value: 'actions', sortable: false },
]

const getFormattedDate = (date) => {
    return new Date(date._seconds * 1000)
        .toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
        })
}

const rowClick = (item) => {
    if (item.type === 'Questionnaire') return
    if (!item.patient?.clinicId) return
    router.push({ name: 'SessionDetails', params: { patientId: item.patientId, id: item.id } })
}

</script>

<style scoped>
.select {
    width: 330px !important;
}

.notes-section {
    border-radius: 14px;
    padding: 4px;
    line-height: 1.5;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

:deep(.summary-content) {
    padding: 16px;
    line-height: 1.6;
}

:deep(.summary-content p) {
    margin-bottom: 1em;
}

:deep(.summary-content ul, .summary-content ol) {
    margin-left: 1.5em;
    margin-bottom: 1em;
}
</style>
