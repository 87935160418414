<template>
    <div>
        <TemplateWizzard />

        <v-card class="mx-auto pb-4 px-4 text-left rounded-card" width="700" elevation="0">
            <v-progress-linear v-if="isPending" color="var(--melon)" indeterminate
                style="width:75vw;"></v-progress-linear>
            <span class="d-flex align-center justify-space-between header-section pt-3">
                <h3>Template Details</h3>
                <div>


                    <v-btn class="save-all-btn" @click="handleSave" elevation="0" :disabled="!templateHeader"
                        :loading="isPending" color="var(--bittersweet)">
                        <v-icon color="white" class="mr-2 save-all-icon">mdi-content-save-all-outline</v-icon>
                        <span class="save-all-text" style="color:white; text-transform: none;">
                            Save
                        </span>
                    </v-btn>
                </div>
            </span>
            <p>Use a descriptive name to remember your template</p>

            <v-text-field class="mt-4" v-model="templateHeader" label="Name *" :rules="[required]" required
                placeholder="e.g. SOAP" />

            <v-select label="Note language" v-model="selectedLanguage"
                :items="['English', 'Spanish', 'French', 'German', 'Arabic', 'Chinese', 'Punjabi', 'Tagalog', 'Russian']"></v-select>

            <v-select label="Specialty" v-model="selectedSpeciality"
                :items="['Psychiatry', 'Psychology', 'Child and Adolescent Psychiatry',
                    'Geriatric Psychiatry', 'Forensic Psychiatry', 'Addiction Psychiatry', 'Consultation-Liaison Psychiatry', 'Neuropsychiatry', 'Psychosomatic Medicine', 'Emergency Psychiatry', 'Sleep Medicine', 'Clinical Psychology', 'Counseling Psychology', 'School Psychology', 'Industrial-Organizational Psychology', 'Health Psychology', 'Neuropsychology', 'Forensic Psychology', 'Sports Psychology', 'Developmental Psychology', 'Social Psychology', 'Experimental Psychology',
                    'Community Psychology', 'Anesthesiology', 'Cardiology', 'Dermatology', 'Emergency Medicine', 'Endocrinology', 'Family Medicine', 'Gastroenterology', 'General Surgery', 'Geriatrics', 'Hematology', 'Infectious Disease', 'Internal Medicine', 'Nephrology', 'Neurology', 'Nutritionist', 'Obstetrics and Gynecology', 'Oncology', 'Ophthalmology', 'Orthopedics', 'Otolaryngology', 'Pediatrics', 'Physical Medicine and Rehabilitation', 'Plastic Surgery', 'Pulmonology', 'Radiology', 'Rheumatology', 'Urology', 'Other',]"></v-select>

            <v-checkbox label="Share with Community" hide-details v-model="shareCommunity"></v-checkbox>
            <v-checkbox :disabled="!isUserAdmin" label="Share with Clinic" hide-details
                v-model="shareClinic"></v-checkbox>
        </v-card> 

        <v-container group="sections">
            <draggable v-model="inputValues" group="sections" @start="drag = true" @end="drag = false">
                <template #item="{ element, index }">
                    <v-card class="mx-auto mb-3 rounded-card" elevation="0" width="700"
                        :disabled='!isUserAdmin && shareClinic'>
                        <v-card-text>
                            <TemplateSection :element="element" :index="index" />
                        </v-card-text>
                        <v-card-actions>
                            <TemplateSectionActions @save="handleSave" @remove="remove(index)" @moveUp="moveUp(index)"
                                @moveDown="moveDown(index)" :isPendingSave="isPending"
                                :templateHeader="templateHeader" />
                        </v-card-actions>
                    </v-card>
                </template>
            </draggable>
        </v-container>

        <v-card class="mx-auto pa-4" width="700" elevation="0">
            <v-btn class="ml-16" variant="plain" @click="addInputField" :disabled='!isUserAdmin && shareClinic'> Add new
                section</v-btn>
        </v-card>

        <Feedback :show-on-left="true" />

        <v-snackbar v-model="errorSnackbar" :timeout="2000" color="bittersweet">
            <span style="color:white;">You must include at least one section in your template.</span>
        </v-snackbar>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import draggable from 'vuedraggable'
import TemplateSection from '../components/TemplateSection.vue';
import TemplateSectionActions from '../components/TemplateSectionActions.vue';
import TemplateWizzard from '@/components/TemplateWizzard.vue';
import getDocument from '@/composables/getDocument'
import getCollection from '@/composables/getCollection'
import useDocument from '@/composables/useDocument'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { trackEvent, TEMPLATE_SHARED, CREATE_CLINICAL_TEMPLATE, UPDATE_CLINICAL_TEMPLATE } from '@/utilities/analyticsService';
import { useRouter } from 'vue-router';
import Feedback from '@/components/Feedback.vue';

const { err, addDoc } = useCollection('summary-templates');
const { user } = getUser();
const router = useRouter()


const templateHeader = ref('My new template')
const selectedLanguage = ref('English')
const selectedSpeciality = ref('Psychiatry');
const shareCommunity = ref(false);
const isUserAdmin = ref(false);
const shareClinic = ref(false);
const inputValues = ref(
    [
        { item: '', dlevel: '', style: 'Bullet points', level: 'Standard' },
        { item: '', dlevel: '', style: 'Bullet points', level: 'Standard' },
        { item: '', dlevel: '', style: 'Bullet points', level: 'Standard' },
        { item: '', dlevel: '', style: 'Bullet points', level: 'Standard' }
    ])
const drag = ref(false)

const detailEnum = {
  'Concise': 'concise',
  'Standard': 'standard',
  'Detailed': 'detailed',
};

const required = (v) => !!v || 'Field is required';

const clinicId = ref(null);
const { documents: userObject } = getCollection('user');

watch(userObject, () => {
    clinicId.value = userObject.value[0]?.clinicId || null
    isUserAdmin.value = userObject.value[0]?.isAdmin || false;
})

const errorSnackbar = ref(false);
const isPending = ref(false);

const handleSave = async () => {
    isPending.value = true;

    const { error, document: clinic } = clinicId.value ? getDocument('clinics', clinicId.value) : {}

    const filteredInputs = inputValues.value.filter(value => value.item !== '');

    if (filteredInputs.length === 0) {
        isPending.value = false;
        errorSnackbar.value = true;
        return;
    }

    const templateItems = filteredInputs.reduce((acc, value, index) => {
        if (value.item) {
            acc[value.item] = {
                dlevel: value.dlevel,
                style: value.style,
                level: detailEnum[value.level] || 'standard',
                order: index.toString()
            };
        }
        return acc;
    }, {});

    
    const res = await addDoc({
        //template: customTemplate,
        items: templateItems,
        templateHeader: templateHeader.value,
        userId: user.value.uid,
        userName: user.value.displayName,
        createdAt: timestamp(),
        language: selectedLanguage.value,
        selectedSpeciality: selectedSpeciality.value,
        shareCommunity: shareCommunity.value,
        shareClinic: shareClinic.value,
        clinicId: shareClinic.value ? clinicId.value : null
    });


    if (shareClinic.value && clinicId.value) {
        const { updateDocument: updateClinic } = await useDocument('clinics', clinicId.value);
        const newTemplate = {
            id: res.id,
            name: templateHeader.value

        };

        await updateClinic({
            templates: [...clinic?.value.templates, newTemplate]
        });

        trackEvent(CREATE_CLINICAL_TEMPLATE, { userId: user.value.uid, email: user.value.email, clinicId: clinicId.value, templateId: res.id });

    }


    isPending.value = false;

    router.push({ name: 'Template', params: { id: res.id } })

}

const addInputField = () => {
    inputValues.value.push({ item: '', dlevel: '', style: 'Bullet points', level: 'Standard' });

}

const moveUp = (index) => {
    if (index > 0) {
        const item = inputValues.value.splice(index, 1)[0];
        inputValues.value.splice(index - 1, 0, item);
    }
}
const moveDown = (index) => {
    if (index < inputValues.value.length - 1) {
        const item = inputValues.value.splice(index, 1)[0];
        inputValues.value.splice(index + 1, 0, item);
    }
}

const remove = (index) => {
    inputValues.value.splice(index, 1);
}

</script>


<style scoped>
.rounded-card {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}
</style>