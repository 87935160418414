<template>
    <v-overlay :model-value="loading" :persistent="true" class="align-center justify-center spinner-overlay">
        <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbarEmail" :timeout="2000" color="gray">
        {{ snackbarEmailText }}
    </v-snackbar>
    <v-card v-if="noQuestionnaires" elevation="0">
        <v-card-title class="d-flex align-start mt-6">
            <div class="d-flex flex-column align-start   ml-7">
                <h4 class="text-left">
                    Send questionnaire to patient
                </h4>
                <p>
                    You don't have any questionnaires available. <span class="text-decoration-underline "
                        style="cursor:pointer" @click="router.push('/community?questionnairesSelected=true')">Click
                        here</span> to import one.
                </p>
            </div>
            <v-btn icon class="absolute-top-right mr-4 mt-2" @click="closeDrawer" elevation="0">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
    </v-card>
    <v-card v-else elevation="0">
        <v-card-title class="d-flex align-start mt-6">
            <div class="d-flex flex-column align-start   ml-7">
                <h4 class="text-left">
                    Send questionnaire to patient
                </h4>
                <p v-if="!!props.patient">You can only assign follow up questionnaires to existing patients</p>

            </div>


            <v-btn icon class="absolute-top-right mr-4 mt-2" @click="closeDrawer" elevation="0">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" class="d-flex flex-column border-0 form-width" style="box-shadow: none;" @submit.prevent
                v-model="isValid">
                <v-autocomplete :disabled="!selectQuestionnaire" v-model="localQuestionnaire" return-object
                    density="comfortable" clearable :loading="questionnaires.length === 0"
                    :item-props="getQuestionnaireProps" :items="questionnaires" label="Select a questionnaire"
                    color="accent" variant="underlined" :rules="[rules.required]"></v-autocomplete>
                <v-autocomplete :disabled="!!props.patient"
                    v-if="localQuestionnaire?.type === 'questionnaire_follow_up' || localQuestionnaire?.type === 'questionnaire_screening'"
                    v-model="patient" @update:modelValue="selectedPatient" density="comfortable" clearable
                    :loading="patients.length === 0" :item-props="getPatientProps" :items="patients"
                    label="Select a patient" color="accent" variant="underlined"
                    :rules="[rules.required]"></v-autocomplete>

                <v-text-field label="Email" color="accent" variant="underlined" v-model="email" type="email"
                    placeholder="johndoe@gmail.com" :rules="[rules.email, rules.required]"></v-text-field>
                <v-text-field color="accent" v-model="subject" label="Subject" variant="underlined" density="compact"
                    type="input" :rules="[rules.required]" class="mt-4"></v-text-field>
                <v-textarea placeholder="Message" v-model="message" variant="outlined" class="mt-4" color="accent"
                    rows="8" />
                <div class="d-flex send-btn">
                    <v-btn color="bittersweet" @click="sendQuestionnaireWithExpiration" :loading="loading"
                        :disabled="!isValid" elevation="0">
                        <span class="btnText">
                            <v-icon class="btnText">mdi-email</v-icon>
                            Send
                        </span>

                    </v-btn>
                </div>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { getFunctions, httpsCallable } from "firebase/functions";
import { ref, getCurrentInstance, defineProps, watch, toRef, onMounted, watchEffect } from 'vue';
import { createToken, sendEmail } from '@/composables/useToken';
import getUser from '@/composables/getUser';
import { trackEvent, QUESTIONNAIRE_SENT } from '@/utilities/analyticsService';
import { useRouter } from 'vue-router'


const { emit } = getCurrentInstance();
const router = useRouter();
const props = defineProps({
    questionnaire: Object,
    selectQuestionnaire: Boolean,
    patient: Object,
})

const functions = getFunctions();
const loading = ref(false);
// move them to another component
const { user } = getUser();
const emailDrawer = ref(false)
const email = ref("")
const subject = ref("")
const message = ref("")
const snackbarEmail = ref(false);
const snackbarEmailText = ref('');
const isValid = ref(false);
const patients = ref([]);
const patient = ref(null);
const form = ref(null)
const localQuestionnaire = ref(props.questionnaire || null)
const questionnaires = ref([]);

const rules = ref(
    {
        email: v => !!(v || '').match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || 'Please enter a valid email',
        required: v => !!v || 'This field is required',

    })

watch(() => props.questionnaire, (newVal) => {
    localQuestionnaire.value = newVal
})

// watch(() => props.startSendingEmail, (value) => {
//     if (value) {
//         fetchPatients();
//         fetchQuestionnaires();
//         openEmailDrawer();
//     }
// })

onMounted(async () => {
    await Promise.all([fetchPatients(), fetchQuestionnaires(), openEmailDrawer()])
})

const openEmailDrawer = async () => {
    const userName = user.value.displayName;
    loading.value = true;
    const questionnaireType = localQuestionnaire.value?.type; // needs mapping to something humanly readable   
    subject.value = `${questionnaireType ?? ''} questionnaire for the upcoming session`
    const defaultMessage = `As discussed, please fill the following questionnaire up prior to the scheduled session. <br> Thank you, <br>${userName}`
    message.value = defaultMessage;
    emailDrawer.value = true;
    loading.value = false;
}
const sendQuestionnaireWithExpiration = async () => {
    loading.value = true;
    const expiration = 7; //days
    const patientQuestionnaireId = crypto.randomUUID();
    const dataToken = { patientQuestionnaireId, questionnaireType: localQuestionnaire.value.type, questionnaireId: localQuestionnaire.value.id, expiration, redirectUrl: `${window.location.origin}/questionnaire-patient`, clientEmail: email.value }
    const { url, token } = await createToken(dataToken);
    const messageWithUrl = `${message.value} <br>Please follow this url: <br>${url}. The url will expire in ${expiration} days from now.`
    const emailPayload = {
        token, questionnaireType: localQuestionnaire.value.type, clientEmail: email.value, subject: subject.value, message: messageWithUrl, patientQuestionnaireId, questionnaireId: localQuestionnaire.value.id, patientId: patient.value?.id
    };
    const emailSent = await sendEmail(emailPayload);
    if (patient.value?.id) {
        await storePatientEmail();
    }

    loading.value = false;
    emailDrawer.value = false;
    snackbarEmail.value = true;
    snackbarEmailText.value = emailSent ? `Email to ${email.value} was sent successfully.` : `Failed to send email to ${email.value}. Please try again later.`;
    trackEvent(QUESTIONNAIRE_SENT, { userId: user.value.uid, userEmail: user.value.email, patientEmail: email.value, patientQuestionnaireId })
    form.value.reset()
    emit('emailSent');
    return emailSent;
}

const fetchPatients = async () => {
    const userId = user.value.uid;
    const getPatients = httpsCallable(functions, 'getPatients');
    const { data } = await getPatients(userId);

    // the below will be used whenever we'll be able to support automatic creation of a patient
    // patients.value = [{ id: null, patientName: 'New patient' }, ...data.map(d => ({ ...d.patient, id: d.id }))]
    patients.value = [...data.map(d => ({ ...d.patient, id: d.id }))]
    // fetch the user's patients and store them into an array {patient name, patient email}  

}

const noQuestionnaires = ref(false)
const fetchQuestionnaires = async () => {
    const getQuestionnaires = httpsCallable(functions, 'getQuestionnaires');

    const { data } = await getQuestionnaires();

    // questionnaires.value = []

    questionnaires.value = data.questionnaires;

    if (questionnaires.value.length === 0) {
        noQuestionnaires.value = true

    }

    if (!!props.patient) {
        questionnaires.value = data.questionnaires.filter(q => q.type === 'questionnaire_follow_up' || q.type === 'questionnaire_screening');
        return
    }

}

const storePatientEmail = async () => {
    // if the email is new and patient already exists store it upon sending 
    const updatePatient = httpsCallable(functions, 'updatePatient');
    await updatePatient({ ...patient.value, email: email.value });
    // track that the email was stored
}

const selectedPatient = (val) => {
    email.value = ""
    if (val) {
        patient.value = val;
        email.value = val.email || "";
    }
}

const getPatientProps = (item) => {
    const title = item.patientFirstName 
        ? `${item.patientFirstName} ${item.patientLastName || ''}`.trim()
        : item.patientName;

    return {
        title: title,
        subtitle: item.email || 'No email provided',
    };
};

const getQuestionnaireProps = (item) => {
    return {
        title: item.title,
        subtitle: item.type,
    }
}

const closeDrawer = () => {
    emailDrawer.value = false;
    emit('emailSent');
}

watchEffect(() => {
    if (!!props.patient) {
        patient.value = props.patient;
        email.value = props.patient.email;
        selectedPatient(patient.value)
    }
})



</script>


<style scoped>
.absolute-top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.btnText {
    text-decoration: none;
    text-transform: none;
    color: white;
}

.form-width {
    max-width: 100% !important;
}

.send-btn {
    align-self: end;
}

.v-v-field__input {
    background: #ffff !important;
    padding-left: 0 !important;
}

.existing-patient-toggle {
    height: 55px;
}

.existing-patient-text {
    color: var(--bittersweet)
}

.questionnaire.fullscreen-drawer {
    z-index: 2000 !important;
}
</style>