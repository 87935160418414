import { MicrophoneAudioRecorder } from "@/classes/recorder.mic";
import { TabAudioRecorder } from "@/classes/recorder.tab";
import { defineStore } from "pinia";
import { ref, nextTick, watch } from "vue";
import { trackEvent, RECORDER_MUTE_LISTENER_PAUSED_AUTOOMATICALLY, RECORDER_RESUME_ERROR, RECORDER_DELETED_LOCAL_BLOB_ON_CLEAR_ERROR, RECORDER_DELETED_LOCAL_BLOB_ON_CLEAR, RECORDER_NO_STREAM_LISTENER_PAUSED, RECORDER_START_WITHOUT_HEADPHONES, RECORDER_START_WITHOUT_HEADPHONES_ERROR, RECORDER_START_WITH_HEADPHONES, RECORDER_START_WITH_HEADPHONES_ERROR, RECORDER_PAUSE, RECORDER_RESUME, RECORDER_STOP_RECORDING, RECORDER_LIVE_TRANSCRIPT_ZERO_BLOB, RECORDER_TOGGLE_DRAWER, RECORDER_STOP_TIMER_ERROR, RECORDER_STOP_RECORDING_ERROR, RECORDER_CREATE_BLOB_ERROR, RECORDER_FINISH_LIVESCRIPT_ERROR, ERROR_UPLOAD_AUDIO, RECORDER_AUDIO_PROGRESS, RECORDER_MUTE_LISTENER } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'
import useLiveTranscript from '@/composables/useLiveTranscript';
import { projectStorage } from "@/firebase/config";
import fixWebmDuration from "fix-webm-duration";
import { sendNotification } from '@/composables/useNotifications';
import { deleteById } from '@/composables/useAudioDB'

const COUNTDOWN_DEFAULT_VALUE = 120 // 2minutes

export const useRecorderStore = defineStore('recoder', () => {
    const drawer = ref(false);
    const patientId = ref('');
    const patient = ref(null);
    const recording = ref(false);
    const paused = ref(false);
    const stream = ref(null);
    const startTime = ref(null);
    const sessionId = ref('');
    const overlay = ref(false);
    const elapsedTime = ref(0);
    const downloadAudioDialog = ref(false);
    const uploadingAudio = ref(false);
    let timerInterval = null;
    const progress = ref(0)
    const idleDialog = ref(false)
    const idleClosed = ref(false)
    const countdown = ref(COUNTDOWN_DEFAULT_VALUE)
    const countDownFinished = ref(false)
    const muted = ref(false)
    const resuming = ref(false)

    let { user } = getUser()
    const { initializeLiveTranscription, handleLiveStream, finishLiveTranscript } = useLiveTranscript();

    let recorder

    function toggleDrawer(item) {
        if (item) {
            patientId.value = item.id;
            patient.value = item;
        }
        drawer.value = !drawer.value;
    }

    async function startRecordingMic() {
        resetTimer();
        startTimer();
        recording.value = true;

        recorder = new MicrophoneAudioRecorder(user.value.uid, patientId.value, sessionId.value);
        try {
            stream.value = await recorder.start(handleDataAvailable, muteListener, noStreamListener)
            trackEvent(RECORDER_START_WITHOUT_HEADPHONES, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })
        } catch (err) {
            trackEvent(RECORDER_START_WITHOUT_HEADPHONES_ERROR, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, error: { errorName: err.name, errorMessage: err.message, errorStack: err.stack } })
            if (err.name === 'NotAllowedError') {
                return errorAlert("Unable to capture your microphone. Please give permission to the application to use the microphone.")
            }
            if (err.message === 'InactiveStreamError') {
                return errorAlert("Unable to capture your microphone. Make sure no other application is using the microphone.")
            }
            if (err.message === 'MicMuted') {
                return errorAlert("Microphone is muted. Please unmute your microphone and try again.")
            }

            return errorAlert("An error occurred while trying to record.")
        }
        // startLiveTranscript()
        startTime.value = Date.now()
    }

    async function startRecordingTab() {
        resetTimer();
        startTimer();
        recording.value = true;

        recorder = new TabAudioRecorder(user.value.uid, patientId.value, sessionId.value);
        try {
            stream.value = await recorder.start(handleDataAvailable, muteListener, noStreamListener)
            trackEvent(RECORDER_START_WITH_HEADPHONES, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })

        } catch (err) {
            trackEvent(RECORDER_START_WITH_HEADPHONES_ERROR, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, error: { errorName: err.name, errorMessage: err.message, errorStack: err.stack } })
            if (err.name === 'NotAllowedError') {
                return errorAlert("The session is not recording. Share a tab to proceed.")
            }

            if (err.name === 'InvalidStateError') {
                return errorAlert("The session is not recording. Share a tab with audio to proceed.")
            }

            if (err.message === 'InactiveStreamError') {
                return errorAlert("Unable to capture your microphone. Make sure no other application is using the microphone.")
            }

            if (err.message === 'MicMuted') {
                return errorAlert("Microphone is muted. Please unmute your microphone and try again.")
            }

            // custom error if no audio tracks are found - for Safari and Firefox
            if (err.message === 'NoAudioTrackError') {
                return errorAlert("Audio capture is not supported in this browser for the selected content. For the best experience, please try using Chrome or Edge. If you are already using one of these broswers, please select a tab to share and not a window or a screen.")
            }

            // for Firefox
            if (err.message === 'The object can not be found here.') {
                return errorAlert("Error recording window or screen. Please check your system settings.")
            }

            return errorAlert("An error occurred while trying to record the tab.")

        }
        // startLiveTranscript()
        startTime.value = Date.now()
    }

    function pauseRecording(fromMuteListener = false) {
        recorder.pause()
        paused.value = true;
        trackEvent(RECORDER_PAUSE, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, fromMuteListener })
    }

    async function resumeRecording() {
        if (resuming.value) return
        try {
            resuming.value = true
            await recorder.resume()
            paused.value = false;
            muted.value = false
            trackEvent(RECORDER_RESUME, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })

        } catch (error) {
            console.log(error)
            trackEvent(RECORDER_RESUME_ERROR, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, error: { message: error.message, name: error.name, stack: error.stack } })

            if (error.message === 'MicMuted') {
                return nextTick(alert("Microphone is muted. Please unmute or change your microphone and try again."))
            }
            nextTick(alert("An error occurred while trying to resume the recording."))
            throw error
        } finally {
            resuming.value = false
        }

    }

    function stopRecording() {

        paused.value = false;
        recording.value = false;
        try {
            stopTimer();
        } catch (error) {
            trackEvent(RECORDER_STOP_TIMER_ERROR, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, error: { message: error.message, name: error.name, stack: error.stack } })
        }

        try {
            recorder.stop()
        } catch (error) {
            trackEvent(RECORDER_STOP_RECORDING_ERROR, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, error: { message: error.message, name: error.name, stack: error.stack } })
        }

        trackEvent(RECORDER_STOP_RECORDING, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })

        try {
            recorder.createAudioBlob()
        } catch (error) {
            trackEvent(RECORDER_CREATE_BLOB_ERROR, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, error: { message: error.message, name: error.name, stack: error.stack } })
        }

        try {
            finishLiveTranscript()
        } catch (error) {
            trackEvent(RECORDER_FINISH_LIVESCRIPT_ERROR, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, error: { message: error.message, name: error.name, stack: error.stack } })
        }

    }

    async function clear() {
        recorder.clear()
        resetTimer();

        try {
            await deleteById(sessionId.value)
            trackEvent(RECORDER_DELETED_LOCAL_BLOB_ON_CLEAR, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })
            console.log('Deleted local blob from indexedDB')
        } catch (err) {
            trackEvent(RECORDER_DELETED_LOCAL_BLOB_ON_CLEAR_ERROR, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, error: { message: err.message, name: err.name, stack: err.stack } })
            console.error('Error deleting audio from indexedDB', err)
        }

        recording.value = false;
        paused.value = false;
        stream.value = null;
        startTime.value = null;
        sessionId.value = '';
        patient.value = null;
        patientId.value = '';
        progress.value = 0;
        idleDialog.value = false
        idleClosed.value = false
        countDownFinished.value = false
        countdown.value = COUNTDOWN_DEFAULT_VALUE
        muted.value = false
        resuming.value = false
    }

    function errorAlert(error) {
        recording.value = false;
        paused.value = false;
        stream.value = null;
        startTime.value = null
        muted.value = false

        nextTick(alert(error))
    }

    function getBlob() {
        return recorder.getAudioBlob()
    }

    function setSessionId(id) {
        sessionId.value = id;
    }

    function setOverlay(value) {
        overlay.value = value;
    }

    // Live Transcript
    async function startLiveTranscript() {
        await initializeLiveTranscription({
            userId: user.value.uid,
            sessionId: sessionId.value,
            patientId: patientId.value
        })
    }

    function handleDataAvailable(blob) {
        if (blob.size === 0) {
            trackEvent(RECORDER_LIVE_TRANSCRIPT_ZERO_BLOB, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })
        }
        handleLiveStream(blob)
    }

    function muteListener(isMuted, fromBeggining) {
        muted.value = isMuted

        if (isMuted && !fromBeggining) {
            sendNotification({
                title: 'mdhub recording alert',
                body: 'Seems like your microphone has been muted. Please unmute so that the recording can continue'
            })
            trackEvent(RECORDER_MUTE_LISTENER_PAUSED_AUTOOMATICALLY, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, isMuted, fromBeggining })
            pauseRecording(true)
            // nextTick(alert("Microphone is muted. Please unmute or change your microphone and try again."))
        }
        console.log('Mute listener event', isMuted, fromBeggining);
        trackEvent(RECORDER_MUTE_LISTENER, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, isMuted, fromBeggining })
    }

    function noStreamListener() {
        console.log('No stream listener')
        trackEvent(RECORDER_NO_STREAM_LISTENER_PAUSED, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value })
        pauseRecording()
        sendNotification({
            title: 'mdhub recording alert',
            body: 'Your mdhub recoding was automatically paused due to a microphone error.'
        })
        nextTick(alert("Microphone change detected. Unable to capture your microphone. Make sure no other application is using the microphone and resume."))
    }

    function startTimer() {
        timerInterval = setInterval(() => {
            if (!paused.value) {
                elapsedTime.value++;
            }
        }, 1000);
    }

    function stopTimer() {
        clearInterval(timerInterval);
        timerInterval = null;
    }

    function resetTimer() {
        elapsedTime.value = 0;
    }

    function setDownloadAudioDialog(value) {
        downloadAudioDialog.value = value;
    }

    function setUploadingAudio(value) {
        uploadingAudio.value = value;
    }

    watch(drawer, (val) => {
        trackEvent(RECORDER_TOGGLE_DRAWER, { userId: user.value.uid, userEmail: user.value.email, source: "Web", patientId: patientId.value, sessionId: sessionId.value, drawer: val })
    })

    function setIdleDialog(val) {
        idleDialog.value = val
    }

    function setIdleClosed(val) {
        idleClosed.value = val
    }

    function setCountdown(val) {
        countdown.value = val
    }

    function setCountDownFinished(val) {
        countDownFinished.value = val
    }

    async function finishRecordingAndGetBlob() {
        setOverlay(true)

        try {
            stopRecording()
        } catch (err) {
            // this fails when firebase user is null and then things break, so in such case prompt user to download audio
            setOverlay(false)
            toggleDrawer()
            setDownloadAudioDialog(true)

            return;
        }

        const blob = getBlob()

        /// Setting duration to the elapsed time in seconds plus 1 (that is done just in case)
        const duration = (elapsedTime.value + 1) * 1000;
        const fixedBlob = await fixWebmDuration(blob, duration, { logger: false });

        return fixedBlob
    }

    async function uploadAudio(patientId, sessionId, file, userId, userEmail) {
        console.log('Starting audio upload for session', { sessionId });

        const audioFilePath = `/${userId}/${patientId}/${sessionId}/${sessionId}.webm`;
        const metadata = { contentType: 'audio/webm' };

        const retries = 2;

        const fileStorageRef = projectStorage.ref(audioFilePath);

        for (let tryCount = 0; tryCount <= retries; tryCount++) {
            try {
                const uploadTask = fileStorageRef.put(file, metadata);

                // throw error in Promise after 5 minutes -- for having a timeout mechanism
                const timeoutPromise = new Promise((_, reject) =>
                    setTimeout(() => {
                        console.log("Timeout triggered");
                        uploadTask.cancel();
                        reject(new Error('Upload timed out'));
                    }, 600000)
                );

                const uploadPromise = new Promise((resolve, reject) => {
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            progress.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log(`Upload is ${progress.value}% done for session ${sessionId}`);
                            trackEvent(RECORDER_AUDIO_PROGRESS, {
                                userId, userEmail, patientId, sessionId, progress: progress.value
                            });
                        },
                        (error) => {
                            console.error('Upload failed', error);
                            reject(error);
                        },
                        () => resolve(uploadTask.snapshot)
                    );
                });

                // if timeoutPromise finishes first, it will cancel upload and retry
                const result = await Promise.race([uploadPromise, timeoutPromise]);
                console.log('File upload result', { sessionId, result });
                console.log('Successfully uploaded file to storage', { sessionId });
                break;
            } catch (error) {
                console.warn(`Failed to upload file on attempt ${tryCount + 1}`, { sessionId, error });
                trackEvent(ERROR_UPLOAD_AUDIO,
                    {
                        userId: userId,
                        userEmail: userEmail,
                        patientId: patientId,
                        sessionId: sessionId,
                        message: error.message,
                        attempt: tryCount
                    }
                )

                if (tryCount === retries) {
                    console.error(`Failed to upload file after ${retries} retries`, { sessionId, error });
                    throw error;
                } else {
                    console.info(`Will retry file upload after error`, { sessionId, error });
                }

                continue;
            }
        }
    }

    return {
        drawer,
        patientId,
        patient,
        recording,
        paused,
        stream,
        startTime,
        sessionId,
        overlay,
        elapsedTime,
        downloadAudioDialog,
        uploadingAudio,
        progress,
        idleDialog,
        idleClosed,
        countdown,
        countDownFinished,
        muted,
        startRecordingMic,
        startRecordingTab,
        pauseRecording,
        resumeRecording,
        stopRecording,
        getBlob,
        clear,
        toggleDrawer,
        setSessionId,
        setOverlay,
        setDownloadAudioDialog,
        setUploadingAudio,
        uploadAudio,
        finishRecordingAndGetBlob,
        setIdleDialog,
        setIdleClosed,
        setCountdown,
        setCountDownFinished,
    }
})