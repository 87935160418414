<template>
    <v-container>
        <v-row justify="center" no-gutters>
            <v-col cols="12" md="8">
                <v-card class="d-flex flex-row" flat>
                    <!-- Image Column - Hide on small screens -->
                    <v-col cols="12" md="6" class="pa-0 hidden-sm-and-down">
                        <v-img :src="require('@/assets/mdhub-therapist.jpg')" cover class="d-flex align-end"></v-img>
                    </v-col>
                    <!-- Content Column -->
                    <v-col cols="12" md="6" class="d-flex flex-column justify-center">
                        <v-card-title class="text-h5 text-center">
                            Thank You!
                        </v-card-title>
                        <v-card-subtitle class="pb-4 mt-5 text-center" style="overflow: visible;">
                            Your subscription has been successfully created.
                            You are being redirected to your patients'
                            list.
                        </v-card-subtitle>

                        <v-card-actions class="justify-center pa-6">
                            <div class="d-flex justify-center">
                                <SavingSpinner label="" style="position: relative;" />
                            </div>
                        </v-card-actions>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import getUser from '@/composables/getUser'
import { getUserCollection } from '@/composables/userService'
import SavingSpinner from '@/components/SavingSpinner.vue'

const { user } = getUser()
const userObject = ref({})

onMounted(async () => {
    if (user.value) {
        userObject.value = await getUserCollection(user.value.uid)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            'event': 'subscription_completed',
            'user_id': user.value.uid,
            'user_email': userObject.value.email
        })
        setTimeout(() => {
            redirectToPatientsList();
        }, 2000)
    }
})

const redirectToPatientsList = () => {
    window.location.href = '/patients/user'
}

</script>

<style scoped>
.v-card-subtitle {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    max-height: 4.5em;
}

.v-card-title {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    max-height: 4.5em;
}

@media (max-width: 600px) {
    .v-card-title {
        font-size: 18px;
    }
}

.d-flex.flex-row {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
}

.pa-0.hidden-sm-and-down {
    display: flex;
    flex-direction: column;
}

.v-img {
    flex-grow: 1;
}
</style>