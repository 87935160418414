<template>
    <v-container fluid class="pl-8 pr-8 pt-2 d-flex flex-column" style="height: 85vh;">
        <v-row v-if="loading">
            <v-col>
                <v-progress-circular color="primary" indeterminate></v-progress-circular>
            </v-col>

        </v-row>



        <div v-if="!loading" class="ml-3" style="max-width: 520px; width: 100%;">
            <v-row>
                <v-col cols="4" class="d-flex align-center">
                    <v-checkbox label="Reviewed" v-model="patientQuestionnaireReviewed"
                        @change="summaryChanged = true; editSummary()"></v-checkbox>
                </v-col>
                <v-col cols="5" class="mr-2" v-if="!existingPatient">
                    <v-autocomplete v-model="patient" @update:modelValue="selectedPatient" density="comfortable"
                        clearable :loading="patients.length === 0" :item-props="getPatientProps" :items="patients"
                        label="Select a patient" color="accent" variant="outlined"></v-autocomplete>
                </v-col>
                <v-col class="mt-2">
                    <SavingSpinner label="Saving" v-if="pendingSaveSummary || pendingSavePatient"
                        style="position:relative;" />
                </v-col>
            </v-row>

        </div>


        <v-row v-if="!loading" class="ma-0 flex-grow-1 justify-center" style="overflow: hidden;">
            <v-col class="d-flex flex-column questionnaires-frame" :cols="responsesCol"
                :style="{ height: '100%', display: minimizeResponses ? 'none !important' : 'flex !important' }">
                <div class="d-flex flex-column responses bg-white rounded-border"
                    style="height: 100%; overflow: hidden;">

                    <v-card elevation="0" class="mb-2 d-flex flex-column" style="height: 100%;">
                        <v-card-text class="justify-space-between d-flex flex-grow-0">
                            <div class="text-h5 title-base questionnaire-title text-left">
                                {{ patientQuestionnaire?.title }}
                            </div>
                            <v-btn class="ml-auto mr-2" variant="text" @click="saveResponses()"
                                :loading="savingResponses"><span
                                    style="text-transform: none; color:var(--bittersweet)">Update
                                    responses</span></v-btn>
                            <v-tooltip :text="responsesCol === 7 ? 'Expand responses' : 'Collapse responses'"
                                location="bottom" z-index="10000">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" class="hand-cursor minimize-btn" size="small"
                                        @click="minimize('summary')" elevation="0">
                                        <v-icon>{{ responsesCol === 7 ? 'mdi-arrow-expand' : 'mdi-arrow-collapse'
                                            }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                        </v-card-text>
                        <div class="items-scroll-container flex-grow-1">
                            <div class="d-flex flex-column items-container">
                                <span :id="item.id" v-for="(item, i) in patientQuestionnaire?.schema" :key="item.id">
                                    <v-card class="mx-auto items" :class="i > 0 ? '' : ''" :link="false" elevation="0">
                                        <v-card-text class="pa-4">
                                            <!-- {{ item }} -->
                                            <Items :id="item.id" :item="item" :preview="true" />
                                        </v-card-text>
                                    </v-card>
                                </span>
                            </div>
                        </div>
                    </v-card>


                </div>
            </v-col>

            <v-col :cols="summaryCol" class="d-flex flex-column questionnaires-frame" @focusout="saveOnFocusOut"
                :style="{ height: '100%', display: minimizeSummary ? 'none !important' : 'flex !important' }">
                <div class="d-flex flex-column responses rounded-border" :class="{ 'edited': summaryChanged }"
                    style="height: 100%; overflow: hidden;">
                    <v-card elevation="0" class="d-flex flex-column" height="100%">
                        <v-card-text class="title-base d-flex justify-space-between flex-grow-0">
                            <div class="d-flex flex-column align-start" v-if="!!patientQuestionnaire">
                                <div class="text-h5 text-left title-base">Questionnaire report</div>

                            </div>

                            <div class="d-flex">
                                <v-btn class="ml-auto mr-2" variant="text" @click="regenerateSummary()"
                                    :loading="regeneratingSummary"><span
                                        style="text-transform: none; color:var(--bittersweet)">Regenerate summary
                                    </span></v-btn>
                                <v-tooltip :text="showEditSummary ? 'Edit summary' : 'Save summary'" location="bottom"
                                    z-index="10000">
                                    <template v-slot:activator="{ props }">
                                        <v-btn :disabled="!canEdit" v-bind="props"
                                            class="hand-cursor edit-btn minimize-btn"
                                            :class="{ 'edit-btn-disabled': !canEdit }" size="small"
                                            @click="editSummary()" elevation="0" :loading="pendingSaveSummary">
                                            <v-icon :color="summaryChanged ? 'var(--bittersweet)' : ''">{{
                                                showEditSummary ?
                                                    'mdi-pencil-outline' :
                                                    'mdi-content-save-outline'
                                            }}</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-tooltip :text="summaryCol === 5 ? 'Expand summary' : 'Collapse summary'"
                                    location="bottom" z-index="10000">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" class="hand-cursor minimize-btn" size="small"
                                            @click="minimize('responses')" elevation="0">
                                            <v-icon>{{ summaryCol === 5 ? 'mdi-arrow-expand' : 'mdi-arrow-collapse'
                                                }}</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                            </div>
                        </v-card-text>
                        <QuillEditor :editing="editingSummary" v-model="patientQuestionnaireSummary"
                            style="overflow-y: auto;" @summary-changed="handleSummaryChanged" />
                    </v-card>
                </div>
            </v-col>
        </v-row>
    </v-container>
    <v-snackbar v-model="pendingSaveSummary" timeout="3000" color="gray">
        Updating questionnaire report
        <template v-slot:actions>
            <v-btn icon @click="pendingSaveSummary = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

    <v-snackbar v-model="responsesSavedSnackbar" timeout="3000" color="gray">
        Responses have been saved
        <template v-slot:actions>
            <v-btn icon @click="responsesSavedSnackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script setup>
import Quill from 'quill';
import { getFunctions, httpsCallable } from "firebase/functions";
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router'
import Items from '@/components/questionnaire-client/Items.vue';
import QuillEditor from './QuillEditor.vue';
import { trackEvent, QUESTIONNAIRE_SUMMARY_EDITED, QUESTIONNAIRE_SUMMARY_EDITED_FAILED } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser';
import SavingSpinner from '@/components/SavingSpinner.vue';
import { getPatientFullName } from '@/utilities/patientUtils';

const route = useRoute();
const { user } = getUser();

const patientQuestionnaire = ref(null)
const loading = ref(false)
const summaryCol = ref(5)
const responsesCol = ref(7)
const minimizeSummary = ref(false);
const minimizeResponses = ref(false);
const patientQuestionnaireSummary = ref('<p>Loading...</p>')
const patientQuestionnaireReviewed = ref(null);
const showEditSummary = ref(true);
const editingSummary = ref(false);
const summaryChanged = ref(false);
const pendingSaveSummary = ref(false);
const pendingSavePatient = ref(false);
const canEdit = ref(true);
const patient = ref(null);


const existingPatient = ref(true);
const patients = ref([]);

const getQuestionnaire = async () => {
    loading.value = true
    const functions = getFunctions();
    const getPatientQuestionnaireById = httpsCallable(functions, 'getPatientQuestionnaireById');
    getPatientQuestionnaireById({ patientQuestionnaireId: route.params.patientQuestionnaireId })
        .then(result => {
            patientQuestionnaire.value = result.data.questionnaire
            patientQuestionnaireSummary.value = result.data.questionnaire.summary ?? `Questionnaire report is not ready yet.`
            patientQuestionnaireReviewed.value = result.data.questionnaire.reviewed
            canEdit.value = patientQuestionnaire.value?.submitted && patientQuestionnaireSummary.value !== 'Questionnaire report is not ready yet.';
        })
        .catch(error => {
            console.log(error)
        })
        .finally(() => {
            loading.value = false
        })
}

onMounted(async () => {
    await getQuestionnaire();
    existingPatient.value = route.params.patientId !== 'unassigned-patient';
    if (!existingPatient.value) {
        await Promise.all([fetchPatients()])
        selectedPatient(null)
    }
})

const minimize = (section) => {
    if (section === 'summary') {
        summaryCol.value = summaryCol.value === 5 ? 1 : 5
        responsesCol.value = 12 - summaryCol.value
        minimizeSummary.value = !minimizeSummary.value;
    } else if (section === 'responses') {
        responsesCol.value = responsesCol.value === 7 ? 1 : 7
        summaryCol.value = 12 - responsesCol.value
        minimizeResponses.value = !minimizeResponses.value;

    }
}

const saveOnFocusOut = async () => {
    return summaryChanged.value && await editSummary();
}

const editSummary = async () => {
    if (summaryChanged.value) {
        // save the summary
        pendingSaveSummary.value = true;
        await updateQuestionnaire();
    }
    editingSummary.value = !editingSummary.value;
    showEditSummary.value = !showEditSummary.value
    summaryChanged.value = false;
    pendingSaveSummary.value = false;

}

const updateQuestionnaire = async () => {
    const functions = getFunctions();
    const updatePatientQuestionnaire = httpsCallable(functions, 'updatePatientQuestionnaire');

    try {
        await updatePatientQuestionnaire({
            patientQuestionnaireId: route.params.patientQuestionnaireId,
            summary: patientQuestionnaireSummary.value,
            reviewed: patientQuestionnaireReviewed.value,
            patientId: patientQuestionnaire.value.patientId || patient.value.id,
            patientEmail: patientQuestionnaire.value.patientEmail || patient.value.email
        })
        trackEvent(QUESTIONNAIRE_SUMMARY_EDITED, { userId: user.value.uid, userEmail: user.value.email, patientQuestionnaireId: route.params.patientQuestionnaireId, summary: patientQuestionnaireSummary.value, reviewed: patientQuestionnaireReviewed.value });
    } catch (error) {
        trackEvent(QUESTIONNAIRE_SUMMARY_EDITED_FAILED, { userId: user.value.uid, userEmail: user.value.email, patientQuestionnaireId: route.params.patientQuestionnaireId, summary: patientQuestionnaireSummary.value, reviewed: patientQuestionnaireReviewed.value });

        console.log(error)
    } finally {
        setTimeout(() => {
            pendingSavePatient.value = false;
        }, 1000)

    }
}

const handleSummaryChanged = (newSummary) => {
    summaryChanged.value = true
    patientQuestionnaireSummary.value = newSummary
}

const getPatientProps = (item) => {
    return {
        title: getPatientFullName(item),
        subtitle: item.email || 'No email provided',
    }
}

const selectedPatient = async (val) => {
    if (val) {
        patient.value = val;
        pendingSavePatient.value = true;
        await updateQuestionnaire();
    }
}
const fetchPatients = async () => {
    const userId = user.value.uid;
    const functions = getFunctions();
    const getPatients = httpsCallable(functions, 'getPatients');
    const { data } = await getPatients(userId);
    patients.value = [...data.map(d => ({ ...d.patient, id: d.id }))]
}


const savingResponses = ref(false)
const responsesSavedSnackbar = ref(false)
const saveResponses = async () => {
    console.log(patientQuestionnaire.value)
    const functions = getFunctions();
    const editSumbittedQuestionnaireResponses = httpsCallable(functions, 'editSumbittedQuestionnaireResponses');
    savingResponses.value = true

    const schema = patientQuestionnaire.value.schema.map(item => {
        const newItem = { ...item }; // Create a shallow copy of the item

        if (newItem.type === 'input_list') {
            newItem.response = newItem.columns.map((column, index) => ({
                col: column,
                responses: newItem.response.map(row => row[index])
            }));
        }
        if (newItem.type === 'input_number') {
            if (!newItem.response) {
                newItem.response = ''
            }
            else if (!!newItem.response && newItem?.response % 1 === 0) {
                newItem.response = newItem.response
            } else {
                newItem.response = parseFloat(newItem.response).toFixed(2)
            }
        }
        if (newItem.type === 'input_single') {
            if (!newItem.requireComment?.includes(newItem.response)) {
                newItem.comment = ''
            }
        }

        if(newItem.type === 'input_multiple') {
          
        }

        return newItem; // Return the modified copy
    });


    try {
        await editSumbittedQuestionnaireResponses({
            patientQuestionnaireId: route.params.patientQuestionnaireId,
            schema
        })
        responsesSavedSnackbar.value = true
    } catch (error) {
        console.log(error)
    }
    finally {
        savingResponses.value = false
    }
}

const regeneratingSummary = ref(false)
const regenerateSummary = async () => {
    const functions = getFunctions();
    const regenerateQuestionnaireSummary = httpsCallable(functions, 'regenerateQuestionnaireSummary');
    regeneratingSummary.value = true
    try {
        await regenerateQuestionnaireSummary({
            patientQuestionnaireId: route.params.patientQuestionnaireId
        })
        await getQuestionnaire()
    } catch (error) {
        console.log(error)
    } finally {
        regeneratingSummary.value = false
    }
}

</script>

<style scoped>
.upper-section {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 4px;
}


.panel {
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
}

::v-deep .panel span {
    background-color: white;
    background: white;

}

.questionnaire-container {
    display: flex;
    justify-content: center;
}

.title-base {
    font-weight: 600;
    color: var(--rich-black);
}

.hand-cursor {
    cursor: pointer;
}

.questionnaires-frame {
    position: relative;
    /* background-color: white; */
    font-family: inherit;
    font-size: inherit;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */

}

.responses {
    /* background-color: white; */
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
    /* border-radius: 4px; */
    overflow-y: scroll;
}

.responses.edited {
    border: 1px solid var(--bittersweet);
}

.items-scroll-container {
    overflow-y: auto;
    height: 100%;
}

.items-container {
    min-height: min-content;
}




.minimize-btn {
    background-color: inherit;


    i {
        color: black;
        /* Replace var(--rich-black) with a valid color value */
    }
}

::v-deep .edit-btn-disabled {
    opacity: 0.3 !important;
}

::v-deep .edit-btn-disabled .v-btn__overlay {
    background-color: white !important;
}
</style>