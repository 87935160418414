<template>
    <v-sheet class="d-flex flex-column justify-center align-center ml-5 mr-5 mt-4 instructions-email" color="#f9fbfd">
        <v-card class="mdhub-card" width="100%" max-width="800px" height="100%">
            <v-card-title v-if="patient" class="left-align mt-7 ml-6"> Secure send to {{ getPatientFullName(patient)
                }}</v-card-title>

            <v-container class="left-align mb-5">
                <v-form ref="form" v-model="isValid" class="email-form pa-6 pt-2">
                    <v-textarea class="text-center rounded-b-xl " rows="10" v-model="context" />
                    <v-text-field v-model="getEmail" :rules="[rules.email]" color="deep-orange" label="Email address"
                        type="email" variant="filled"></v-text-field>
                    <v-file-input label="Add attachment" show-size :rules="[rules.attachment]"
                        @update:modelValue="onAddAttachment"></v-file-input>

                    <div class="d-flex checkEmail">
                        <v-checkbox v-model="keepPatientEmail"
                            class="d-flex justify-left ml-0 email-checkbox"></v-checkbox>
                        <span class="email-text">Save patient email</span>
                    </div>
                    <div class="d-flex">
                        <v-checkbox v-model="agreement" :rules="[rules.required]"
                            class="d-flex justify-left ml-0 email-checkbox"></v-checkbox>
                        <span class="email-text">I have obtained consent from the patient to send personal health
                            information by email.</span>
                    </div>

                </v-form>
            </v-container>

            <v-card-actions class="d-flex justify-end mr-8 mb-8">
                <v-btn variant="text" color="#FF7260" class="cancel-btn" @click="closeForm" :disabled="overlay"> CANCEL
                </v-btn>


                <v-btn variant="text" color="#FF7260" dark class="mdhub-btn " @click="sendEmail" :disabled="!isValid"
                    :loading="overlay">
                    SEND
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="isMailSent" :timeout="timeout" color="gray">
            Your email has been successfuly sent to {{ getEmail }}
        </v-snackbar>
        <v-snackbar v-model="isMailFailed" :timeout="timeout" color="red">
            Error: Your email has not been sent to {{ getEmail }}
        </v-snackbar>
    </v-sheet>

</template>

<script>
import getDocument from '@/composables/getDocument';
import useDocument from '@/composables/useDocument';
import { getFunctions, httpsCallable } from "firebase/functions";
import { ref } from 'vue'
import { trackEvent, SEND_SECURE_EMAIL_START, SEND_SECURE_EMAIL_SUCCESS } from '@/utilities/analyticsService';
import { getPatientFullName } from '@/utilities/patientUtils';

export default {
    props: ['emailBody', 'patientId', 'sessionDate', 'sessionId', 'userEmail'],
    methods: {
        convertToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(',')[1]); // Extract Base64 string from data URI
                reader.onerror = error => reject(error);
                reader.readAsDataURL(file);
            });
        },
        async onAddAttachment(fileInput) {
            const file = fileInput[0];
            if (file) {
                try {
                    const base64File = await this.convertToBase64(file);
                    this.attachment =
                    {
                        fileName: file.name,
                        contentType: file.type,
                        content: base64File
                    }

                    console.log(this.attachment)
                } catch (error) {
                    console.error('Error converting to Base64:', error);
                }
            }
        },
        async sendEmail() {
            // send and close
            if (this.keepPatientEmail) {
                await this.updatePatient({
                    ...this.patient,
                    email: this.email
                })
            }

            // prepare send
            this.overlay = true;
            const sessionDate = new Date(this.sessionDate.seconds * 1000);

            const formattedDate = sessionDate.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric'
            });

            const subject = `Recap of Your ${formattedDate} Visit `;
            const formatContext = this.context.replace(/\n/g, "<br>").replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            const bottom = `Do not reply to this email. Instead contact your medical provider directly.
The information contained in this transmission may contain privileged and confidential information, including patient information protected by federal and state privacy laws. It is intended only for the use of the person(s) named above. If you are not the intended recipient, you are hereby notified that any review, dissemination, distribution, or duplication of this communication is strictly prohibited. If you are not the intended recipient, please contact the sender by reply email and destroy all copies of the original message.
`
            const options = {
                from: 'no-reply@mdhub.ai',
                to: [this.email],
                forceSecureNotification: "true",
                subject,
                text_content: this.context,
                html_content: `<html><head></head><body>${formatContext}<br><br>${bottom}</body></html>`,
                attachments: this.attachment.fileName && [this.attachment]
            }

            console.log(options)

            trackEvent(SEND_SECURE_EMAIL_START, { userId: this.patient.userId, userEmail: this.userEmail, patientId: this.patient.id, sessionId: this.sessionId, options, source: "Web" })

            // call the firebase function here 
            const functions = getFunctions();
            const sendSecureEmail = httpsCallable(functions, 'sendSecureEmail');
            try {
                const res = await sendSecureEmail(options);
                trackEvent(SEND_SECURE_EMAIL_SUCCESS, { userId: this.patient.userId, userEmail: this.userEmail, patientId: this.patient.id, sessionId: this.sessionId, source: "Web" })

                this.isMailSent = true;
            } catch (error) {
                this.isMailFailed = true;
                console.log(`Error sending email to patient ${error}`)
            }
            this.overlay = false;
            setTimeout(() => this.$emit('close-email'), 2000)


        },
        closeForm() {
            this.$emit('close-email');
        }

    },
    computed: {
        getEmail: {
            get() {
                if (this.email || this.email === '') return this.email
                this.email = this.patient && this.patient.email ? this.patient.email : this.email
                return this.email
            },
            set(newValue) {
                this.email = newValue;
            }
        }
    },
    data() {
        return {
            context: this.emailBody,
            sessionDate: this.sessionDate,
            sessionId: this.sessionId,
            isValid: false,
            email: undefined,
            agreement: false,
            keepPatientEmail: false,
            isMailSent: false,
            isMailFailed: false,
            overlay: false,
            timeout: 2000,
            attachment: {},
            rules: {
                email: v => !!(v || '').match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || 'Please enter a valid email',
                attachment: value => {
                    return (
                        !value ||
                        !value.length ||
                        value[0].size < 50000000 ||
                        'Avatar size should be less than 50 MB!'
                    )
                },
                required: v => !!v || 'This field is required',
            },
        }
    },
    setup(props) {
        const { updateDocument: updatePatient } = useDocument('patients', props.patientId);
        const { error, document: patient } = getDocument('patients', props.patientId);

        return { updatePatient, patient, getPatientFullName }
    }
}

</script>

<style scoped>
.instructions-email {
    overflow-y: scroll;
}

.checkEmail {
    align-items: center;
}

.checkEmail .email-checkbox {
    flex: 0
}

.checkEmail .email-checkbox+span {
    color: #4f515a9c;
}


.email-form {
    max-width: 800px;
}

.v-label {
    margin-bottom: 0 !important;
}

.mdhub-card {
    overflow-y: scroll;
}

.mdhub-card form {
    border: 0;
    box-shadow: none;
}

.mdhub-card .mdhub-btn {
    width: auto
}

.cancel-btn {
    background-color: transparent !important;
    color: #4f515a9c !important;
    text-transform: none !important;
    height: 34px !important;
    width: auto;
}

.left-align {
    text-align: left;
}
</style>
