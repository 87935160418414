import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'
import getUser from "./getUser";

let unsubscribe = null

const getInteractions = (docId, myref) => {

    const error = ref(null)
    let collectionRef = projectFirestore.collection('conversations').doc(docId).collection('interactions').orderBy('interactionCount', 'asc').orderBy('timestamp', 'asc')

    if (!!myref.value) {
        unsubscribe()
    }

    unsubscribe = collectionRef.onSnapshot(snap => {
        let results = []
        snap.docs.forEach(doc => {
            results.push({ ...doc.data(), id: doc.id })
        });

        myref.value = results.map(doc => {
            return {
                speaker: doc.speaker === 'ai-agent' ? 'ai-agent' : 'Patient',
                text: doc.speaker === 'ai-agent' ? doc.label : doc.text,
            }
        })

        error.value = null
    }, err => {
        error.value = 'could not fetch the data'
    })


    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub());
    });
    return { error }
}

export default getInteractions
