<template>
    <div class="container">
        <div class="d-flex align-center mb-5">
            <h5 class="mb-0">Patients Questionnaires</h5>
            <p v-if="sentToday != null" class="ml-2 mb-0 text-body-2">({{ sentToday }}/3 sent today)</p>
            <v-progress-circular class="ml-2" v-else color="primary" indeterminate size="16"></v-progress-circular>
        </div>
        <v-data-table width="500px" class="rounded-table" :headers="headers" :items="filteredQuestionnaires"
            :hover="true" :loading="loading">
            <template v-slot:top>
                <v-toolbar flat color="#FFF" class="rounded-toolbar">
                    <v-text-field class="ml-6 custom-bg" v-model="search" prepend-icon="mdi-magnify"
                        label="Search questionnaires" base-color="#fff" single-line hide-details variant="solo-filled"
                        clearable flat></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn size="large" class="white--text text--lighten-1 mr-4" @click="manageTemplate" variant="text">
                        <v-icon dense class="add-btn">mdi-plus</v-icon>
                        <span class="white--text add-btn"> Create </span>
                    </v-btn>

                    <!-- :disabled="sentToday == null" -->
                    <v-btn @click="sendQuestionnaire" :disabled="sentToday == null" :loading="loading" color="#FF7260" class="mdhub-btn mb-2">
                        Send
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
                <tr @click="clickRow(item)" :disabled="!item.patientId"
                    :class="['hand-cursor', !item.patientId ? 'disabled-row' : '']">


                    <td class="text-left">
                        <template v-if="item.submitted">
                            <template v-if="item.reviewed">
                                Reviewed
                            </template>
                            <template v-else class="d-flex align-center justify-center">
                                <v-badge inline dot color="var(--electric-blue)"></v-badge>
                                <span class="ml-1">Ready for Review</span>
                            </template>
                        </template>
                        <template v-else>
                            -
                        </template>
                    </td>

                    <td class="py-2">
                        <div class="d-flex align-center">
                            <v-avatar size="50" class="mr-4" color="#e2e8f0">
                                <v-overlay>
                                    <template v-slot:activator>
                                        <span class="initials">{{ getInitial(getPatientFullName(item)) }}</span>
                                    </template>
                                </v-overlay>
                            </v-avatar>
                            <span class="">{{ getPatientFullName(item) || '-' }}</span>
                        </div>
                    </td>

                    <td class="mx-auto text-left">{{ item.title }}</td>
                    <td class="text-left">{{ item.type === 'questionnaire_intake' ? 'Intake' : 'Follow Up' }}</td>
                    <td class="text-left">{{ item.patientEmail }}</td>
                    <td class="text-left">{{ formatDate(item.createdAt) }}</td>
                    <td class="text-left">{{ item.submittedAt ? formatDate(item.submittedAt) : (item.submitted ? 'Yes' :
                        'No') }}</td>
                </tr>
            </template>
            <template v-slot:no-data>
                <div class="d-flex flex-column align-center py-8">
                    <p class="mb-0">To get started </p>
                    <p>Create or import a questionnaire from our Community</p>
                    <v-btn elevation="0" class="mdhub-btn" to="/community?questionnairesSelected=true">Import</v-btn>
                    <!-- <p class="mt-5">Want to know more? Check our <a href="https://google.com" target="_blank">our video tutorial</a></p> -->
                </div>
            </template>
        </v-data-table>

        <v-navigation-drawer v-model="emailDrawer" temporary location="right" :width="800" :scrim="false">
            <QuestionnaireEmail :selectQuestionnaire="true" 
                @emailSent="emailSent" />
        </v-navigation-drawer>

        <v-snackbar v-model="snackbar" color="gray" elevation="24">
            <span>If you want to send more: Reach out to us through contact us button or email us to <a
                    href="mailto:support@mdhub.ai">support@mdhub.ai</a></span>

            <template v-slot:actions>
                <v-btn color="pink" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script setup>
import { getFunctions, httpsCallable } from "firebase/functions";
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import QuestionnaireEmail from "@/components/questionnaires/QuestionnaireEmail.vue";
import moment from 'moment'
import { Timestamp } from "@firebase/firestore";
import { getPatientFullName } from '@/utilities/patientUtils';

const router = useRouter()

const headers = [
    { title: 'Status', align: 'start', key: 'reviewed' },
    { title: 'Patient', align: 'start', key: 'patientName' },
    { title: 'Title', align: 'start', key: 'title' },
    { title: 'Type', align: 'start', key: 'type' },
    { title: 'Patient Email', align: 'start', key: 'patientEmail' },
    { title: 'Sent', align: 'start', key: 'createdAt' },
    { title: 'Submitted', align: 'start', key: 'shared' },
]
const patientsQuestionnaires = ref([]);
const filteredQuestionnaires = computed(() => {
    if (search.value) {
        return patientsQuestionnaires.value.filter((questionnaire) => {
            return questionnaire.title.toLowerCase().includes(search.value.toLowerCase())
            || getPatientFullName(questionnaire).toLowerCase().includes(search.value.toLowerCase());
        });
    }
    return patientsQuestionnaires.value;
})
const loading = ref(false);

const functions = getFunctions();

const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '';
}

const sentToday = ref(null);
onMounted(async () => {
    loading.value = true
    const getPatientQuestionnaires = httpsCallable(functions, 'getPatientQuestionnaires');
    const { data } = await getPatientQuestionnaires({ patientId: null });
    patientsQuestionnaires.value = data.questionnaires;
    loading.value = false;

    getSentToday()

})

const emailSent = async () => {
    emailDrawer.value = false
    loading.value = true
    const functions = getFunctions();
    const getPatientQuestionnaires = httpsCallable(functions, 'getPatientQuestionnaires');
    const { data } = await getPatientQuestionnaires({ patientId: null });
    patientsQuestionnaires.value = data.questionnaires;
    await getSentToday()
    loading.value = false;
}

const search = ref('');

const clickRow = (questionnaire) => {
    console.log(questionnaire)
    router.push({ name: 'PatientQuestionnaire', params: { patientId: questionnaire.patientId, patientQuestionnaireId: questionnaire.id } })
}

const manageTemplate = () => {
    router.push({ name: 'Questionnaires' }); // Replace 'Questionnaires' with the actual route name for the questionnaires page
}

const emailDrawer = ref(false);
const snackbar = ref(false);
const sendQuestionnaire = () => {
    if (sentToday.value >= 3) {
        snackbar.value = true;
        return
    }
    emailDrawer.value = true;
}

const getSentToday = async () => {
    sentToday.value = null;
    const getSentTodayCount = httpsCallable(functions, 'getSentTodayCount');
    const { data: sentTodayCount } = await getSentTodayCount();
    sentToday.value = sentTodayCount.count;
}

const formatDate = (date) => {
    return moment(new Timestamp(date._seconds, date._nanoseconds).toDate()).format('MM-DD-YYYY HH:mm')
}

</script>

<style scoped>
.rounded-toolbar {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #FFF;
}

.rounded-table {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}

.hand-cursor {
    cursor: pointer;
}

.disabled-row {
    pointer-events: none;
    opacity: 0.5;
    cursor: auto;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

.initials {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: #64748b;
    height: 100%;
    width: 100%;
}

.add-btn {
    background-color: transparent !important;
    color: var(--bittersweet);
    text-transform: none !important;
    width: auto;
}
</style>