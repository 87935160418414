<template>
    <div class="container pb-12 ">
        <div class="d-flex flex-column">
            <h5 class="text-left">Your Questionnaires</h5>
            <p class="text-left">Here you can see the questionnaires that you have created or imported from
                the Community</p>

        </div>
        <v-data-table width="500px" class="rounded-table" :headers="headers" :items="filteredQuestionnaires" :items-per-page.sync="itemsPerPage" 
        @update:items-per-page="(value) => (itemsPerPage = value)" :hover="true" :loading="loading">
            <template v-slot:top>
                <v-toolbar flat color="#FFF" class="rounded-toolbar">
                    <v-text-field class="ml-6 custom-bg" v-model="search" prepend-icon="mdi-magnify"
                        label="Search questionnaires" base-color="#fff" single-line hide-details variant="solo-filled"
                        clearable flat></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <div class="d-flex align-center mb-2">
                        <v-btn class="white--text text--lighten-1 text-decoration-none" variant="text"
                            to="/community?questionnairesSelected=true">
                            <span class="white--text add-btn">+ Import</span>
                        </v-btn>

                        <v-btn class="white--text text--lighten-1 mr-4" @click="addQuestionnaire" :loading="loading"
                            color="#FF7260">
                            <span class="white--text add-btn"> Create </span>
                        </v-btn>
                        <v-btn class="mdhub-btn" variant="text" style="text-transform: none;"
                            @click="openEmailDrawer()">

                            Send to Patient
                        </v-btn>
                    </div>
                </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
                <tr @click="clickRow(item)" class="hand-cursor">
                    <td>

                        <v-tooltip v-if="!copyingUrl || item.id !== selectedQuestionnaire.id"
                            text="Copy the questionnaire url in order to re use it in your website" location="bottom"
                            z-index="10000">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" :hidden="!shouldSeeCopyUrl" class="mr-4 hover-icon"
                                    @click.stop="getUrl(item)">
                                    mdi-content-copy
                                </v-icon>

                            </template>
                        </v-tooltip>

                        <v-progress-circular class="mr-4 hover-icon" :size="20" v-else color="primary"
                            indeterminate></v-progress-circular>

                        <v-tooltip text="Send questionnaire to patient" location="bottom" z-index="10000">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" class="hover-icon" size="small"
                                    @click.stop="openEmailDrawer(item)">
                                    mdi-email
                                </v-icon>

                            </template>
                        </v-tooltip>

                    </td>
                    <td class="mx-auto text-left">{{ item.title }}</td>
                    <td class="text-left">{{ mapQuestionnaireType(item.type) }}</td>
                    <td class="text-left">{{ item.description?.substring(0, 50) + (item.description?.length > 50 ? '...'
                        : '') }}</td>
                    <td class="text-left">{{ item.createdAt }}</td>
                    <td class="text-left">

                        <v-btn size="small" class="ma-2" color="red-lighten-2" icon="mdi-delete" variant="text"
                            @click.stop="openDeleteDrawer(item)"></v-btn>
                    </td>
                </tr>
            </template>
            <template v-slot:no-data>
                <div class="d-flex flex-column align-center py-8">
                    <p class="mb-0">To get started </p>
                    <p>Create or import a questionnaire from our Community</p>
                    <p>Use the Intake, PHQ-9, GAD-7, or your custom Questionnaires to get and scoring, which inform the
                        patient profile.
                    </p>
                    <v-btn elevation="0" class="mdhub-btn" to="/community?questionnairesSelected=true">Import</v-btn>
                    <p class="mt-5">Want to know more? Check our <span class="text-decoration-underline"
                            style="cursor: pointer;" @click="openVideo">our video tutorial</span></p>
                </div>
            </template>
        </v-data-table>

        <v-navigation-drawer v-model="createDrawer" temporary location="right" :width="800">
            <CreateQuestionnaire />
        </v-navigation-drawer>

        <v-navigation-drawer v-model="emailDrawer" temporary location="right" :width="800" :scrim="false">
            <QuestionnaireEmail :questionnaire="selectedQuestionnaire" :selectQuestionnaire="selectQuestionnaire"
                @emailSent="emailDrawer = false;" />
        </v-navigation-drawer>

        <v-navigation-drawer v-model="deleteDrawer" temporary location="right" :width="800">
            <div class="d-flex flex-column align-start pa-5">
                <h5 class="text-left">Are you sure you want to delete the {{ deletingQuestionnaire?.title }}?</h5>
                <p class="text-left">If you proceed you will no longer have acccess to it</p>
                <div class="mt-4">
                    <v-btn elevation="0" width="auto" class="mdhub-btn" @click="deleteDrawer = false">
                        Cancel</v-btn>
                    <v-btn elevation="0" width="auto" class="ml-2" :loading="deleting"
                        @click="deleteQuestionnaire">delete
                        questionnaire</v-btn>
                </div>
            </div>
        </v-navigation-drawer>
        <Feedback />
        <TemplateWizzard :title="'Need help adding the questionnaire into your website'"
            :description="'Let our team handle that for you.'" :page="'Questionnaire'" />
    </div>
    <v-snackbar color="gray" elevation="24" v-model="copyingUrl" :timeout="2000" z-index="10000">
        {{ copyUrl }}
    </v-snackbar>
</template>

<script setup>
import { getFunctions, httpsCallable } from "firebase/functions";
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import CreateQuestionnaire from '@/components/questionnaires/CreateQuestionnaire.vue';
import QuestionnaireEmail from "@/components/questionnaires/QuestionnaireEmail.vue";
import Feedback from '@/components/Feedback.vue';
import { trackEvent, QUESTIONNAIRE_PAGE_VISIT, QUESTIONNAIRE_VIDEO_CLICK } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'
import getReusableUrl from '@/composables/getReusableUrl';
import TemplateWizzard from "@/components/TemplateWizzard.vue";
import { watch } from 'vue';


const { user } = getUser()
const router = useRouter()
const functions = getFunctions();

const headers = [
    { title: 'Send', align: 'start', key: 'actions' },
    { title: 'Title', align: 'start', key: 'title' },
    { title: 'Type', align: 'start', key: 'type' },
    { title: 'Description', align: 'start', key: 'description' },
    { title: 'Created at', align: 'start', key: 'createdAt' },
    { title: 'Delete', align: 'start', key: 'delete' }

]
const questionnaires = ref([]);
const filteredQuestionnaires = computed(() => {
    if (search.value) {
        return questionnaires.value.filter((questionnaire) => {
            const headerTrimmed = questionnaire.title.toLowerCase().replace(/ /g, '');
            const searchValueTrimmed = search.value.toLowerCase().replace(/ /g, '');
            return headerTrimmed.includes(searchValueTrimmed) || questionnaire.title.toLowerCase().includes(search.value.toLowerCase());
        });
    }
    return questionnaires.value;
})
const loading = ref(false);
const shouldSeeCopyUrl = user.value?.email?.includes('mdhub');
const copyUrl = ref('')
const copyingUrl = ref(false)
const openCopyUrl = ref(false);

onMounted(async () => {
    trackEvent(QUESTIONNAIRE_PAGE_VISIT, {
        userId: user.value.uid,
        userEmail: user.value.email
    })
    await fetchQuestionnaires();
});

const itemsPerPage = ref(
  localStorage.getItem('globalItemsPerPage')
    ? JSON.parse(localStorage.getItem('globalItemsPerPage'))
    : 10
);

watch(itemsPerPage, (val) => {
  localStorage.setItem('globalItemsPerPage', JSON.stringify(val));
});

const fetchQuestionnaires = async () => {
    loading.value = true
    const getQuestionnaires = httpsCallable(functions, 'getQuestionnaires');
    const { data } = await getQuestionnaires();
    questionnaires.value = data.questionnaires.map((questionnaire) => {
        return {
            ...questionnaire,
            createdAt: getFormattedDate(questionnaire.createdAt)
        }
    });
    loading.value = false
}

const search = ref('');
const createDrawer = ref(false);

const addQuestionnaire = () => {
    createDrawer.value = true;
}

const clickRow = (item) => {
    router.push({ name: 'QuestionnaireBuilder', params: { id: item.id } })
}

const selectedQuestionnaire = ref(null);
const emailDrawer = ref(false);
const selectQuestionnaire = ref(false)

const openEmailDrawer = (item) => {
    if (!!item) {
        selectedQuestionnaire.value = item;
        selectQuestionnaire.value = false;
    } else {
        selectedQuestionnaire.value = null;
        selectQuestionnaire.value = true;
    }
    emailDrawer.value = true;
}

const getUrl = async (item) => {
    selectedQuestionnaire.value = item;
    copyingUrl.value = true
    const urlPath = process.env.NODE_ENV === "production" ? 'https://app.mdhub.ai/' : 'https://mdhub-staging.web.app/';
    const urlData = { questionnaireType: item.type, questionnaireId: item.id, expiration: 10000, redirectUrl: `${urlPath}questionnaire-patient` }
    const reusableUrl = await getReusableUrl(urlData);
    // here we need to actually copy the url to the clipboard
    await navigator.clipboard.writeText(reusableUrl.url)
    copyUrl.value = "URL copied successfully"
    setTimeout(() => {
        copyingUrl.value = false
        copyUrl.value = ""
    }, 2000)

}


const deleteDrawer = ref(false);
const deletingQuestionnaire = ref(null);
const deleting = ref(false);

const openDeleteDrawer = (item) => {
    deletingQuestionnaire.value = item;
    deleteDrawer.value = true;
}

const deleteQuestionnaire = async () => {
    deleting.value = true
    const deleteQuestionnaire = httpsCallable(functions, 'deleteQuestionnaire');
    try {
        await deleteQuestionnaire({ questionnaireId: deletingQuestionnaire.value.id });
        questionnaires.value = questionnaires.value.filter((questionnaire) => questionnaire.id !== deletingQuestionnaire.value.id);
        deleteDrawer.value = false;
        fetchQuestionnaires();
    } catch (error) {
        console.error('Error deleting questionnaire', error)
    }
    deleting.value = false
}

const openVideo = async () => {
    trackEvent(QUESTIONNAIRE_VIDEO_CLICK, {
        userId: user.value.uid,
        userEmail: user.value.email
    })
    window.open('https://www.youtube.com/watch?v=kGe8E62dAXg', '_blank');
}

const getFormattedDate = (date) => {
    const localeDate = new Date(date._seconds * 1000)
        .toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",

        });

    const splitDate = localeDate.split('/');
    const supportedDate = splitDate[2] + '-' + splitDate[0] + '-' + splitDate[1];
    return supportedDate
}

const mapQuestionnaireType = (type) => {
    switch (type) {
        case 'questionnaire_intake':
            return 'Intake';
        case 'questionnaire_follow_up':
            return 'Follow Up';
        case 'questionnaire_screening':
            return 'Screening';
        case 'questionnaire_intake_agent':
            return 'Agent Intake';
        default:
            return 'Unknown';
    }
}

</script>

<style scoped>
.rounded-toolbar {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #FFF;
}

.rounded-table {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}

.absolute-top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.btnText {
    text-decoration: none;
    text-transform: none;
    color: white;
}

.initials {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: #64748b;
    height: 100%;
    width: 100%;
}

.v-field--variant-solo-filled .v-field__overlay {
    opacity: 0 !important;
}

::v-deep .v-field__overlay {
    opacity: 0.01 !important;
}


::v-deep .v-text-field:focus-within .v-field__overlay {
    opacity: 0.05 !important;
}

.hand-cursor {
    cursor: pointer;
}

.add-btn {
    background-color: transparent !important;
    color: var(--bittersweet);
    text-transform: none !important;
    width: auto;
}
</style>